import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import Isvg from 'react-inlinesvg';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    UncontrolledDropdown,
    Input
} from 'reactstrap';


import logo from '../assets/svg/logo.svg';
import locationIcon from '../assets/svg/f-location.svg';
import phoneIcon from '../assets/svg/f-phone.svg';
import faxIcon from '../assets/svg/f-fax.svg';
import mailIcon from '../assets/svg/f-mail.svg';
import aboutImage from '../assets/images/about.jpg';
import arrowLight from '../assets/svg/arrow-white.svg';

import fbIcon from '../assets/svg/f-fb.svg';
import instIcon from '../assets/svg/f-inst.svg';
import twtIcon from '../assets/svg/f-twt.svg';
import ytIcon from '../assets/svg/icon-youtube.svg';
import liIcon from '../assets/svg/f-li.svg';
import arrow from '../assets/svg/nsl-arrow.svg';
import sf1 from '../assets/images/sf1.jpg';
import blogImage from '../assets/images/b1.jpg';
import serviceIcon1 from '../assets/svg/s1.svg';
import serviceIcon2 from '../assets/svg/s2.svg';
import serviceIcon4 from '../assets/svg/s4.svg';
import aboutImageBig from '../assets/images/about-huge.jpg';
import historyIcon1 from '../assets/svg/h1.svg';
import historyIcon2 from '../assets/svg/h2.svg';
import historyIcon4 from '../assets/svg/h4.svg';
import arrowDark from '../assets/svg/arrow-right.svg';

import Page from '../containers/page';
import ReferenceArticle from '../components/referenceArticle';
import BlogArticle from '../components/blogArticle';

export class HistoryPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData
        };
    }

    componentDidMount() {

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get()



    }

    get = () => {
        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                }, () => this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang)))
            })
        }


    }
    componentDidUpdate(prevProps) {
        if (prevProps[0].location.pathname != this.props[0].location.pathname) {
            if (typeof window !== 'undefined') { window.scrollTo(0, 0); }
            this.get()


            for (let i = 0; i < this.props.loadData.length; i++) {
                this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                    this.setState({
                        ...data
                    })
                })
            }
        }



    }


    render() {
        return (
            <div>

                <div className="header">
                    <div className="header-content">
                        <Container>
                            <Row>
                                <Col lg="6" sm="6">
                                    <h1 className="breadcrumb-title">Istorijat</h1>
                                </Col>
                                <Col lg="6" sm="6">
                                    <span className="breadcrumb">
                                        <a>Početna</a>
                                        //
                                        <a>O nama</a>
                                        //
                                        <a>Istorijat</a>
                                    </span>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>   

                <div className="history-details">
                    <Container>
                        <Row>
                            <Col lg="2">
                                <ul className="header-nav">
                                    <span>O nama</span>
                                    <li style={{ borderTop: 0 }}>
                                        <a>Oprema</a>
                                    </li>
                                    <li>
                                        <a>Licence</a>
                                    </li>
                                    <li>
                                        <a>Sertifikati</a>
                                    </li>
                                    <li>
                                        <a>Istorijat</a>
                                    </li>
                                    <li>
                                        <a>Zaposleni</a>
                                    </li>
                                </ul>
                            </Col>
                            <Col lg="10">
                                <Row>
                                    <Col lg="7">
                                        <div className="history-details-content">
                                            <h2>Istorijat</h2>
                                            <p>Preduzeće “Bijeljina put” je nastalo statusnim primjenama
                                            Državnog komunalnog preduzeća “4. Juli” Bijeljina, koje je
                                            osnovano 1949. godine. Danas je “Bijeljina put” d.o.o. Bijeljina u
                                            privatnom vlasništvu. Od momenta sprovedene privatizacije,
                                            2001. godine “Bijeljina put” uspješno obavlja izgradnju,
                                            rekonstrukciju i asfaltiranje puteva i izvršava godišnje radove na
                                            održavanju i zaštiti magistralnih, regionalnih i lokalnih puteva
                                            od posebnog interesa za Bosnu i Hercegovinu.</p>
                                            <p>Uprava i zaposleni su svjesni da su kvalitet i zaštita okoline
                                            integrisani u ukupan sistem preduzeća, ključ za uspješan prodor
                                            i opstanak na tržištu. Preduzeće je takođe opredjeljeno da
                                            stalnim ulaganjem u znanje i savremenu tehnologiju postiže
                                            kvalitetne efekte i bolji stručni i proizvodni rad.</p>
                                        </div>
                                    </Col>
                                    <Col lg="5">
                                        <div className="history-details-image">
                                            <img src={aboutImage} />
                                            <div className="shape-3"></div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={{ size: 10, offset: 2 }}>
                                <p>
                                Investitor se smatra partnerom u poslu i razvija se partnerski odnos što podrazumijeva zajednički interes za
                                visokim kvalitetom izvođenja, poštovanje rokova, i racionalnu izgradnju svakog objekta. Svjesni, da naše
                                djelovanje mijenja prostor i prirodnu sredinu, promišljeno pristupamo poslu, razumijevajući i uvažavajući
                                potrebe stanovništva.
                                <br/><br/>
                                Posebnu pažnju posvećujemo sigurnosti na radu, zaštiti zdravlja uposlenih, i zaštiti imovine kako preduzeća,
                                tako i drugih lica.
                                <br/><br/>
                                Firma Bijeljina Put d.o.o posjeduje sertifikat za sistem upravljanja prema standardu ISO 9001: 2008. Sertifikat se
                                odnosi na područje izgradnje, rekonstrukcije, asfaltiranja, održavanja i zaštite puteva, proizvodnju asfalta
                                i betona.
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <div className="history-features">
                    <Container>
                        <Row>
                            <Col lg="6" sm="6">
                                <div className="history-feature">
                                    <Isvg src={historyIcon1} />
                                    <h6>Naša politika</h6>
                                    <p>je razvojno i održivo orjentisana, uključuje
                                    kreativnost, profesionalnost, nove tehnologije,
                                    očuvanje životne sredine i ulaganje u
                                    ljudske resurse</p>
                                </div>
                            </Col>
                            <Col lg="6" sm="6" style={{ marginBottom: 75 }}>
                                <div className="history-feature">
                                    <Isvg src={historyIcon2} />
                                    <h6>Naši partneri</h6>
                                    <p>uvijek mogu da računaju na nas, a novi izazovi
                                    su motiv za jaku saradnju</p>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="6" sm="6">
                                <div className="history-feature">
                                    <Isvg src={historyIcon2} />
                                    <h6>Naša misija</h6>
                                    <p>je zadovoljan investitor, zadovoljan klijent i
                                    najkvalitetnija usluga</p>
                                </div>
                            </Col>
                            <Col lg="6" sm="6">
                                <div className="history-feature">
                                    <Isvg src={historyIcon4} />
                                    <h6>Naša podrška</h6>
                                    <p>je naš svaki uspješno realizvoan projekat u
                                    saradnji sa našim partenrima</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <div className="history-fullscreen">
                    <div className="history-image">
                        <img src={aboutImageBig} />
                    </div>
                    <Isvg src={ytIcon} />
                    <div className="history-text">
                        <div className="history-text-content">
                            <h2>Izgradnja javnih puteva</h2>
                            <p>Građevinski radovi iz oblasti komunalne
                            infrastrukture, i iz oblasti hidrogradnje i
                            niskogradnje (stabilizacija i uređenje korita
                            rijeka i kanala melioracioni – komasacioni
                            radovi, donji stroj željezničkih pruga, donji i
                            gornji stroj poljoprivrednih i sportskih
                            aerodroma, sportskih igrališta i dr.)
                            </p>
                            <div class="history-fact">
                                <Row>
                                    <Col lg="6">
                                        <span>2.548</span>
                                        <p>Zadovoljnih klijenata</p>
                                    </Col>
                                    <Col lg="6">
                                        <span>582.548</span>
                                        <p>Asvaltiranih kilometara</p>
                                    </Col>
                                </Row>
                            </div>
                            <p>Građevinski radovi iz oblasti komunalne
                            infrastrukture, i iz oblasti hidrogradnje i
                            niskogradnje (stabilizacija i uređenje korita
                            rijeka i kanala melioracioni!</p>
                        </div>
                    </div>
                </div>

                <div className="history-about">
                    <Container>
                        <Row>
                            <Col lg="5">
                                <div className="about-image">
                                    <img src={aboutImage} />
                                    <div className="shape-2">
                                        <h3>Izgradnja, rekonstrukcija i održavanje javnih puteva</h3>
                                    </div>
                                </div>
                            </Col>
                            <Col lg="7">
                                <div className="about-text">
                                    <span>Bijeljina Put d.o.o.</span>
                                    <h2>Izgradnja, rekonstrukcija i održavanje javnih puteva</h2>
                                    <p>BIJELJINA PUT d.o.o. kao članica FORTIS Grupe izvodi različite vrste
                                    radova u oblasti niskogradnje i hidrogradnje, bavi se
                                    održavanjem putne mreže koju čine magistralni, regionalni i
                                    lokalni putevi, bavi se proizvodnjom i ugradnjom asfalta, betona
                                    i preradom šljunka.
                                    <br/><br/>
                                    Iza sebe ima značajne reference, a ispred sebe viziju
                                    dugoročnog razvoja i unapređenja svojih usluga.
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>

                </div>

                <div className="work">
                    <div className="container">

                        <div className="work-title">
                            <h6>Radovi koje smo izvodili</h6>
                            <h2>Naše rerefence</h2>
                        </div>

                        <ul className="tabs tabs-light">
                            <li className="active-tab">Proizvodnja</li>
                            <li>Odrzavanje</li>
                            <li>Izgradnja</li>
                        </ul>
                        
                        <div className="tabs-content-container">
                            <div>
                                <Row className="tabs-content">
                                    <Col lg="4">
                                        <ReferenceArticle />
                                    </Col> 
                                    <Col lg="4">
                                        <ReferenceArticle />
                                    </Col>
                                    <Col lg="4">
                                        <ReferenceArticle />
                                    </Col>
                                </Row>
                            </div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </div> 

                <div className="help-section">
                    <a href="">
                        Sve reference
                        <span>
                            <Isvg src={arrowDark} />
                        </span>
                    </a>
                </div>

                <div className="blog">
                    <div className="container">
                        <div className="blog-header">
                            <div className="blog-title">
                                <h2>Latest news</h2>
                            </div>
                            <Link to={this.state.linkAllNews && this.state.linkAllNews[this.props.lang]}><button>ALL ARTICLES<span><Isvg src={arrowDark} /></span></button></Link>
                        </div>
                        <div className="blog-content">
                            <Row>
                            {
                                    this.state.blogLatest && this.state.blogLatest.map((item, idx) => {
                                        return (
                                            <Col lg="4">
                                                <BlogArticle
                                                    item={item}
                                                    image={item.image}
                                                    title={item.name}
                                                    shortDescription={item.shortDescription}
                                                    dateTS={item.dateTS}
                                                    alias={item.alias}
                                                    link={Object.translate(item, 'link', this.props.lang)}
                                                />
                                            </Col>
                                        )

                                    })
                                }
                            </Row>
                        </div>
                    </div>
                </div>

            </div>
            

        )
    }
}

export default Page(HistoryPage);