import HistoryPage from './views/history';
import HomePage from './views/homePage';
import BlogPage from './views/blogPage';
import BlogDetailsPage from './views/blogDetailsPage';
import GalleryPage from './views/galleryPage';
import ArchivePage from './views/archive';
import ContactPage from './views/contact';
import EquipmentPage from './views/equipmentPage';
import ReferencePage from './views/referencePage';
import ReferenceDetailsPage from './views/referenceDetailsPage';
import AgencyPage from './views/agency';
import FortisGroup from './views/fortisGroup';

import ArticlePage from './views/articlePage';

const linkTranslates = {

    'en': {
        '/': '/en',
        '/gallery': '/en/gallery',
        '/blog/:menu': '/en/blog/:menu',
        '/:menu/:category': '/en/:menu/:category',
        
        '/pretraga/:search': '/en/search/:search',
        '/history': '/en/history',
        '/archive': '/en/archive',
        '/fortis-group': '/fortis-group',
        '/contact': '/en/contact',
        '/equipment': '/en/equipment',
        '/reference/:category': '/en/reference/:category',
        '/:menu/:category/:alias': '/en/:menu/:category/:alias',
        '/agency': '/en/agency',

    },
}

export const routes = [
    {
        path: "/",
        generateSeoTags: (data, lang) => {
            return {
                title: 'Početna'.translate(lang) + ' - Bijeljina put'.translate(lang),
                description: ''.translate(lang),
                'og:image': 'https://bijeljinaput.novamedia.agency/share.png'
            }
        },
        component: HomePage,
        loadData: [
            (fetchFunction, lang, match) => {
                return fetchFunction(`https://bijeljinaput-api.novamedia.agency/latest/articles`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },

                }).then(res => res.json()).then((result) => {

                    for (let i = 0; i < result.items.length; i++) {
                        result.items[i].dateTS = new Date(result.items[i].date + " " + result.items[i].time).getTime() / 1000;
                    }


                    return {
                        blogLatest: result.items,
                        linkAllNews: result.linkAllNews

                    }
                })

            },
            (fetchFunction, lang, match) => {
                return fetchFunction(`https://bijeljinaput-api.novamedia.agency/home`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },

                }).then(res => res.json()).then((result) => {
                    console.log(result)
                    return {
                        home: result

                    }
                })

            },
        ]
    },
    {
        path: "/fortis-group",
        generateSeoTags: (data, lang) => {
            return {
                title: 'Fortis group'.translate(lang) + ' - Bijeljina put'.translate(lang),
                description: ''.translate(lang),
                'og:image': 'https://bijeljinaput.novamedia.agency/share.png'
            }
        },
        component: FortisGroup,
        loadData: [
            (fetchFunction, lang, match) => {
                return fetchFunction(`https://bijeljinaput-api.novamedia.agency/latest/articles`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },

                }).then(res => res.json()).then((result) => {

                    for (let i = 0; i < result.items.length; i++) {
                        result.items[i].dateTS = new Date(result.items[i].date + " " + result.items[i].time).getTime() / 1000;
                    }


                    return {
                        blogLatest: result.items,
                        linkAllNews: result.linkAllNews

                    }
                })

            },
            (fetchFunction, lang, match) => {
                return fetchFunction(`https://bijeljinaput-api.novamedia.agency/fortis-group`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },

                }).then(res => res.json()).then((result) => {
                    console.log(result)
                    return {
                        data: result

                    }
                })

            },
        ]
    },
    
   
    {
        path: "/gallery",
        generateSeoTags: (data, lang) => {
            return {
                title: 'Galerija'.translate(lang) + ' - Bijeljina put'.translate(lang),
                description: ''.translate(lang),
                'og:image': 'https://bijeljinaput.novamedia.agency/share.png'
            }
        },
        component: GalleryPage,
        loadData: [
            (fetchFunction, lang, match) => {
                return fetchFunction(`https://bijeljinaput-api.novamedia.agency/latest/articles`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },

                }).then(res => res.json()).then((result) => {

                    for (let i = 0; i < result.items.length; i++) {
                        result.items[i].dateTS = new Date(result.items[i].date + " " + result.items[i].time).getTime() / 1000;
                    }


                    return {
                        blogLatest: result.items,
                        linkAllNews: result.linkAllNews

                    }
                })

            },
        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {
                return fetchFunction(`https://bijeljinaput-api.novamedia.agency/gallery`, {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        lang: lang
                    })
                }).then(res => res.json()).then((result) => {

                    return {
                        page: query.page ? query.page : 0,
                        gallery: result.items,
                        total: result.total,
                    }
                })

            }
        ]
    },
    
    {
        path: "/blog/:menu",
        generateSeoTags: (data, lang) => {
            return {
                title: 'Aktualnosti'.translate(lang) + ' - Bijeljina put'.translate(lang),
                description: ''.translate(lang),
                'og:image': 'https://bijeljinaput.novamedia.agency/share.png'
            }
        },
        component: BlogDetailsPage,
        loadData: [
            (fetchFunction, lang, match) => {
                return fetchFunction(`https://bijeljinaput-api.novamedia.agency/articles/${match.params.menu}`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },

                }).then(res => res.json()).then((result) => {
                    if (result.item && result.item.date && result.item.time)
                        result.item.dateTS = new Date(result.item.date + " " + result.item.time).getTime() / 1000;
                    
                    return {
                        data: result.item,
                    }
                })

            },
            (fetchFunction, lang, match) => {
                return fetchFunction(`https://bijeljinaput-api.novamedia.agency/latest/articles`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },

                }).then(res => res.json()).then((result) => {

                    for (let i = 0; i < result.items.length; i++) {
                        result.items[i].dateTS = new Date(result.items[i].date + " " + result.items[i].time).getTime() / 1000;
                    }


                    return {
                        blogLatest: result.items,
                        linkAllNews: result.linkAllNews

                    }
                })

            },

        ]
    },
    {
        path: "/pretraga/:search",
        generateSeoTags: (data, lang) => {
            return {
                title: 'Pretraga'.translate(lang) + ' - Bijeljina put'.translate(lang),
                description: ''.translate(lang),
                'og:image': 'https://bijeljinaput.novamedia.agency/share.png'
            }
        },
        component: BlogPage,
        loadData: [],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {
                return fetchFunction(`https://bijeljinaput-api.novamedia.agency/articles`, {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        lang: lang
                    })
                }).then(res => res.json()).then((result) => {
                    for (let i = 0; i < result.items.length; i++) {
                        result.items[i].dateTS = new Date(result.items[i].date + " " + result.items[i].time).getTime() / 1000;
                    }
                    return {
                        page: query.page ? query.page : 0,
                        items: result.items,
                        total: result.total,
                    }
                })

            },
            
        ]
    },
    {
        path: "/history",
        generateSeoTags: (data, lang) => {
            return {
                title: 'Istorijat'.translate(lang) + ' - Bijeljina put'.translate(lang),
                description: ''.translate(lang),
                'og:image': 'https://bijeljinaput.novamedia.agency/share.png'
            }
        },
        component: HistoryPage,
        loadData: [
            (fetchFunction, lang, match) => {
                return fetchFunction(`https://bijeljinaput-api.novamedia.agency/latest/articles`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },

                }).then(res => res.json()).then((result) => {

                    for (let i = 0; i < result.items.length; i++) {
                        result.items[i].dateTS = new Date(result.items[i].date + " " + result.items[i].time).getTime() / 1000;
                    }


                    return {
                        blogLatest: result.items,
                        linkAllNews: result.linkAllNews
                    }
                })

            },
        ]
    },
    {
        path: "/archive",
        generateSeoTags: (data, lang) => {
            return {
                title: 'Arhiva'.translate(lang) + ' - Bijeljina put'.translate(lang),
                description: ''.translate(lang),
                'og:image': 'https://bijeljinaput.novamedia.agency/share.png'
            }
        },
        component: ArchivePage,
        loadData: [
            (fetchFunction, lang, match) => {
                return fetchFunction(`https://bijeljinaput-api.novamedia.agency/documents`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },

                }).then(res => res.json()).then((result) => {

                    return {
                        documents: result,
                    }
                })

            },
            (fetchFunction, lang, match) => {
                return fetchFunction(`https://bijeljinaput-api.novamedia.agency/latest/articles`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },

                }).then(res => res.json()).then((result) => {

                    for (let i = 0; i < result.items.length; i++) {
                        result.items[i].dateTS = new Date(result.items[i].date + " " + result.items[i].time).getTime() / 1000;
                    }


                    return {
                        blogLatest: result.items,
                        linkAllNews: result.linkAllNews
                    }
                })

            },
        ]
    },
    {
        path: "/equipment",
        generateSeoTags: (data, lang) => {
            return {
                title: 'Oprema'.translate(lang) + ' - Bijeljina put'.translate(lang),
                description: ''.translate(lang),
                'og:image': 'https://bijeljinaput.novamedia.agency/share.png'
            }
        },
        component: EquipmentPage,
        loadData: [
            (fetchFunction, lang, match) => {
                return fetchFunction(`https://bijeljinaput-api.novamedia.agency/latest/articles`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },

                }).then(res => res.json()).then((result) => {

                    for (let i = 0; i < result.items.length; i++) {
                        result.items[i].dateTS = new Date(result.items[i].date + " " + result.items[i].time).getTime() / 1000;
                    }


                    return {
                        blogLatest: result.items,
                        linkAllNews: result.linkAllNews
                    }
                })

            },
        ]
    },

    {
        path: "/:menu/:category",
        generateSeoTags: (data, lang) => {
            return {
                title: (data.menuName ? data.menuName : '') + ' - '  + (data.categoryName ? data.categoryName : '') + '  - Bijeljina put'.translate(lang),
                description: ''.translate(lang),
                'og:image': 'https://bijeljinaput.novamedia.agency/share.png'
            }
        },
        component: ArticlePage,
        loadData: [
            (fetchFunction, lang, match) => {
                return fetchFunction(`https://bijeljinaput-api.novamedia.agency/latest/articles`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },

                }).then(res => res.json()).then((result) => {

                    for (let i = 0; i < result.items.length; i++) {
                        result.items[i].dateTS = new Date(result.items[i].date + " " + result.items[i].time).getTime() / 1000;
                    }


                    return {
                        blogLatest: result.items,
                        linkAllNews: result.linkAllNews
                    }
                })

            },

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {
                return fetchFunction(`https://bijeljinaput-api.novamedia.agency/articles/${match.params.menu}/${match.params.category}`, {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        lang: lang,
                        subcategory: query.subcategory
                    })
                }).then(res => res.json()).then((result) => {
                    if (result.items && result.items.length)
                        for (let i = 0; i < result.items.length; i++) {
                            result.items[i].dateTS = new Date(result.items[i].date + " " + result.items[i].time).getTime() / 1000;
                        }
                    return {
                        page: query.page ? query.page : 0,
                        subcategory: query.subcategory ? query.subcategory : 0,
                        items: result.items,
                        total: result.total,
                        categoryName: result.categoryName,
                        subcategories: result.subcategories,
                        categories: result.categories,
                        menuName: result.menuName,
                        menuType: result.menuType,
                        latestReferences: result.latestReferences,
                        linkToReference: result.linkToReference,
                        linkMenuCategory: result.linkMenuCategory
                    }
                })

            }
        ]

    },
    {
        path: "/reference/:category",
        generateSeoTags: (data, lang) => {
            return {
                title: 'Reference'.translate(lang) + ' - Bijeljina put'.translate(lang),
                description: ''.translate(lang),
                'og:image': 'https://bijeljinaput.novamedia.agency/share.png'
            }
        },
        component: ReferencePage,
        loadData: [
            (fetchFunction, lang, match) => {
                return fetchFunction(`https://bijeljinaput-api.novamedia.agency/latest/articles`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },

                }).then(res => res.json()).then((result) => {

                    for (let i = 0; i < result.items.length; i++) {
                        result.items[i].dateTS = new Date(result.items[i].date + " " + result.items[i].time).getTime() / 1000;
                    }


                    return {
                        blogLatest: result.items,
                        linkAllNews: result.linkAllNews
                    }
                })

            },

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {
                return fetchFunction(`https://bijeljinaput-api.novamedia.agency/references/${match.params.category}`, {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        lang: lang
                    })
                }).then(res => res.json()).then((result) => {
                    for (let i = 0; i < result.items.length; i++) {
                        result.items[i].dateTS = new Date(result.items[i].date + " " + result.items[i].time).getTime() / 1000;
                    }
                    return {
                        page: query.page ? query.page : 0,
                        items: result.items,
                        total: result.total,
                    }
                })

            }
        ]

    },
    {
        path: "/:menu/:category/:alias",
        generateSeoTags: (data, lang) => {
            return {
                title: (data.menuName ? data.menuName : '') + ' - '  + (data.item && data.item.name ? data.item.name[lang] : '') + ' - Bijeljina put'.translate(lang),
                description: ''.translate(lang),
                'og:image': 'https://bijeljinaput.novamedia.agency/share.png'
            }
        },
        component: ReferenceDetailsPage,
        loadData: [
            (fetchFunction, lang, match) => {
                return fetchFunction(`https://bijeljinaput-api.novamedia.agency/latest/articles`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },

                }).then(res => res.json()).then((result) => {

                    for (let i = 0; i < result.items.length; i++) {
                        result.items[i].dateTS = new Date(result.items[i].date + " " + result.items[i].time).getTime() / 1000;
                    }


                    return {
                        blogLatest: result.items,
                        linkAllNews: result.linkAllNews
                    }
                })

            },
            (fetchFunction, lang, match) => {
                return fetchFunction(`https://bijeljinaput-api.novamedia.agency/articles/${match.params.menu}/${match.params.category}/${match.params.alias}/${lang}`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },

                }).then(res => res.json()).then((result) => {
                    if (result && result.item && result.item.date && result.item.time)
                        result.item.dateTS = new Date(result.item.date + " " + result.item.time).getTime() / 1000;

                    return {
                        item: result.item,
                        categoryName: result.categoryName,
                        subcategories: result.subcategories,
                        categories: result.categories,
                        menuName: result.menuName,
                        menuType: result.menuType,
                        categoryLink: result.categoryLink
                    }
                })

            },
        ]
    },
    {
        path: "/agency",
        generateSeoTags: (data, lang) => {
            return {
                title: 'Djelatnost'.translate(lang) + ' - Bijeljina put'.translate(lang),
                description: ''.translate(lang),
                'og:image': 'https://bijeljinaput.novamedia.agency/share.png'
            }
        },
        component: AgencyPage,
        loadData: [
            (fetchFunction, lang, match) => {
                return fetchFunction(`https://bijeljinaput-api.novamedia.agency/latest/articles`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },

                }).then(res => res.json()).then((result) => {

                    for (let i = 0; i < result.items.length; i++) {
                        result.items[i].dateTS = new Date(result.items[i].date + " " + result.items[i].time).getTime() / 1000;
                    }


                    return {
                        blogLatest: result.items,
                        linkAllNews: result.linkAllNews
                    }
                })

            },
        ]
    },
    {
        path: "/contact",
        generateSeoTags: (data, lang) => {
            return {
                title: 'Kontakt'.translate(lang) + ' - Bijeljina put'.translate(lang),
                description: ''.translate(lang),
                'og:image': 'https://bijeljinaput.novamedia.agency/share.png'
            }
        },
        component: ContactPage,
        loadData: []
    },

];

export function generateRoutes(r) {
    let newRoutes = [];
    for (let i = 0; i < r.length; i++) {
        newRoutes.push(r[i]);

        newRoutes.push({
            ...r[i],
            path: linkTranslates.en[r[i].path]
        })
    }
    return newRoutes;
}