import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import Isvg from 'react-inlinesvg';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    UncontrolledDropdown,
    Input,
    Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';

import ReactPaginate from 'react-paginate';
import Page from '../containers/page';
import sf1 from '../assets/images/sf1.jpg';
import arrowRight from '../assets/svg/arrow-white.svg';
import blogImage from '../assets/images/b1.jpg';
import ReferenceArticle from '../components/referenceArticle';
import arrowDark from '../assets/svg/arrow-right.svg';
import BlogArticle from '../components/blogArticle';
import pdf from '../assets/pdf.png';
import word from '../assets/word.png';
import xx from '../assets/xx.png';
import ytIcon from '../assets/svg/icon-youtube.svg';
import YouTube from 'react-youtube';
import moment from 'moment';
import { API_ENDPOINT } from '../constants';

import close_ico from '../assets/svg/close-ico.svg';

export class ReferencePage extends Component {
    constructor(props) {
        super(props);
        this.updateStateFromSearch = this.updateStateFromSearch.bind(this);
        this.updateParam = this.updateParam.bind(this);
        this.get = this.get.bind(this);


        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);

        this.state = {
            promotedProjects: [],
            tab: 0,
            gallery: [],
            activeIndex: 0,
            detailItem: {},
            activeModalVideo: null,
            ...props.initialData
        };
    }

    updateStateFromSearch(callback) {
        let broken = this.props[0].location.search.split('?').pop().split('&');
        let params = { page: 0 };
        for (let i = 0; i < broken.length; i++) {
            if (broken[i].indexOf('=') !== -1) {
                params[broken[i].split('=')[0]] = broken[i].split('=')[1];
            }
        }

        this.setState({
            category: null,
            tag: null,
            items: []
        }, () => {
            this.setState(params, callback);

        })

    }


    updateParam(name, value) {

        let broken = this.props[0].location.search.split('?').pop().split('&');
        let params = {};
        for (let i = 0; i < broken.length; i++) {
            if (broken[i].indexOf('=') !== -1) {
                params[broken[i].split('=')[0]] = broken[i].split('=')[1];
            }
        }

        params[name] = value;

        let paramsArr = [];
        for (var key in params) {
            if (params.hasOwnProperty(key) && params[key]) {

                paramsArr.push(key + "=" + params[key]);
            }
        }



        let search = '?' + paramsArr.join('&');


        this.props[0].history.push(this.props[0].location.pathname + search);
        ////console.log(search);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.updateStateFromSearch(() => {
                this.get()
            });
        }
        if (prevProps[0].location.pathname != this.props[0].location.pathname) {
            this.get()
        }
    }

    componentDidMount() {
        if (this.props[0].location.state && this.props[0].location.state.tab) {
            this.setState({ tab: this.props[0].location.state.tab })
        }

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                })
            })
        }




        this.updateStateFromSearch(this.get);




    }
    onExiting() {
        this.animating = true;
    }

    onExited() {
        this.animating = false;
    }

    next() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === this.state.gallery.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    }

    previous() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? this.state.gallery.length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    }

    goToIndex(newIndex) {
        if (this.animating) return;
        this.setState({ activeIndex: newIndex });
    }

    get() {
        this.updateParam('page', this.state.page);
        window.scrollTo(0, 0);

        fetch(API_ENDPOINT + `/articles/${this.props[0].match.params.menu}/${this.props[0].match.params.category}`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                page: this.state.page,
                lang: this.props.lang,
                subcategory: this.state.tab
            })
        }).then(res => res.json()).then((result) => {
            if (result.items && result.items.length)
                for (let i = 0; i < result.items.length; i++) {
                    result.items[i].dateTS = new Date(result.items[i].date + " " + result.items[i].time).getTime() / 1000;
                }
            this.setState({
                items: result.items,
                total: result.total,
                categoryName: result.categoryName,
                subcategories: result.subcategories,
                categories: result.categories,
                menuName: result.menuName,
                menuType: result.menuType,
                latestReferences: result.latestReferences,
                linkToReference: result.linkToReference,
                linkMenuCategory: result.linkMenuCategory
            }, () => {
                this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

                if (this.state.subcategories && this.state.subcategories.length && (this.state.menuType == 3 || this.state.menuType == 1) && this.state.categories && this.state.categories.length) {
                    let cat = this.state.categories.filter((item) => Object.translate(item, 'name', this.props.lang) == this.state.categoryName)[0];
                    let subcat = this.state.subcategories.filter((item) => item.category == cat._id)[0];

                    if (this.state.tab == 0) {

                        if (this.state.items && this.state.items.length) {
                            for (let i = 0; i < this.state.items.length; i++) {
                                if (!this.state.items[i].subcategory) {
                                    this.setState({ detailItem: this.state.items[i] }, () => {
                                        if (this.state.detailItem && this.state.detailItem.gallery && this.state.detailItem.gallery.length) {
                                            this.setState({ gallery: this.state.detailItem.gallery, galleryLength: this.state.detailItem.gallery.length })
                                        }
                                    });
                                    return;
                                }
                            }
                        }

                    } else {
                        let category = this.state.categories.filter((item) => Object.translate(item, 'name', this.props.lang) == this.state.categoryName)[0];
                        let listOfSubCat = this.state.subcategories.filter((item) => item.category == category._id);
                        if (listOfSubCat.filter((item) => item._id == this.state.tab).length == 0) {
                            this.setState({ tab: subcat._id }, () => {
                                if (this.state.items && this.state.items.length) {
                                    for (let i = 0; i < this.state.items.length; i++) {
                                        if (this.state.items[i].subcategory == this.state.tab) {
                                            this.setState({ detailItem: this.state.items[i] }, () => {
                                                if (this.state.detailItem && this.state.detailItem.gallery && this.state.detailItem.gallery.length) {
                                                    this.setState({ gallery: this.state.detailItem.gallery, galleryLength: this.state.detailItem.gallery.length })
                                                }
                                            });
                                            return;
                                        }
                                    }
                                }
                                

                            })
                        } else {

                            this.setState({ tab: this.state.tab }, () => {
                                if (this.state.items && this.state.items.length) {
                                    for (let i = 0; i < this.state.items.length; i++) {
                                        if (this.state.items[i].subcategory == this.state.tab) {
                                            this.setState({ detailItem: this.state.items[i] }, () => {
                                                if (this.state.detailItem && this.state.detailItem.gallery && this.state.detailItem.gallery.length) {
                                                    this.setState({ gallery: this.state.detailItem.gallery, galleryLength: this.state.detailItem.gallery.length })
                                                }
                                            });
                                            return;
                                        }
                                    }
                                }
                                


                            })
                        }


                    }
                    if (this.state.tab == 0 && this.state.menuType == 1) {
                        this.setState({ tab: subcat._id }, () => {
                            let subcategoryName = {
                                ba: ''
                            }
                            for(let i=0; i<this.state.subcategories.length; i++){
                                if(this.state.subcategories[i]._id == this.state.tab){
                                    subcategoryName = this.state.subcategories[i].name;
                                }
                            }
                            this.setState({ subcategoryName })
                        })
                    }



                } else if (this.state.subcategories && this.state.subcategories.length) {
                    if (this.state.subcategories.filter((item) => item._id == this.state.tab).length == 0) {
                        this.setState({ tab: this.state.subcategories[0]._id }, () => this.get())
                    }


                }
                if (this.state.menuType == 2) {
                    if (this.state.items && this.state.items.length && this.state.items[0].gallery && this.state.items[0].gallery.length) {
                        this.setState({ gallery: this.state.items[0].gallery, galleryLength: this.state.items[0].gallery.length })
                    }

                }

                this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang))
                
                if(this.state.tab != 0){
                    let subcategoryName = {
                        ba: ''
                    }
                    for(let i=0; i<this.state.subcategories.length; i++){
                        if(this.state.subcategories[i]._id == this.state.tab){
                            subcategoryName = this.state.subcategories[i].name;
                        }
                    }
                    this.setState({ subcategoryName })
                }
            })
        })


    }




    render() {
        var dynamicHeight = typeof window !== 'undefined' ? window.innerWidth / 100 * 42 : 0;
        const opts = {
            height: typeof window !== 'undefined' && window.innerWidth > 1120 ? '715px' : dynamicHeight,
            width: typeof window !== 'undefined' && window.innerWidth > 1120 ? '1105px' : '100%',
            playerVars: {
                // https://developers.google.com/youtube/player_parameters
                autoplay: 0,
            },
        };
        const { activeIndex } = this.state;
        const slides = this.state.gallery && this.state.gallery.map((item) => {
            return (
                <CarouselItem
                    tag="div"
                    key={item}
                    onExiting={this.onExiting}
                    onExited={this.onExited}
                >
                    <div className="lightbox-item">
                        <img src={API_ENDPOINT + item} />
                    </div>

                </CarouselItem>
            );
        });

        return (
            <div>

                <div className="header">
                    <div className="header-content">
                        <Container>
                            <Row>
                                <Col lg="6" sm="6">
                                    <h1 className="breadcrumb-title">{this.state.menuName}</h1>
                                </Col>
                                <Col lg="6" sm="6">
                                    <span className="breadcrumb">
                                        <Link to="/">{"Početna".translate(this.props.lang)}</Link>
                                        //
                                        <Link to={Object.translate(this.state, 'linkMenuCategory', this.props.lang)}>{this.state.menuName}</Link>
                                        //
                                        <a>{this.state.categoryName}</a>
                                    </span>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>

                <div>
                    {
                        this.state.menuType == 2 ?
                            <>
                                <div className="reference-page">
                                    <Container>
                                        <Row>
                                            <Col lg="2">
                                                <ul className="header-nav">
                                                    <span>{this.state.menuName}</span>
                                                    {
                                                        this.state.categories && this.state.categories.map((item, idx) => {
                                                            return (
                                                                <li style={{ borderTop: 0 }} className={this.props[0].location.pathname == `/${this.props[0].match.params.menu}/${Object.translate(item, 'link', this.props.lang)}` ? 'active' : ''}>
                                                                    <Link to={`/${this.props[0].match.params.menu}/${Object.translate(item, 'link', this.props.lang)}`}>{Object.translate(item, 'name', this.props.lang)}</Link>
                                                                </li>
                                                            )
                                                        })
                                                    }


                                                </ul>
                                            </Col>
                                            {/* {
                                                this.state.menuType == 1 ?
                                                    <Col lg="10">
                                                        <h2 className="headline" style={{ paddingLeft: 15 }}>{this.state.categoryName}</h2>
                                                        <div className="tabs tabs-dark tabs-reference">
                                                            {
                                                                this.state.subcategories && this.state.subcategories.map((item, idx) => {
                                                                    return (
                                                                        <li className={this.state.tab == item._id ? "active-tab" : ''} onClick={() => { this.setState({ tab: item._id, page: 0 }, () => this.get()) }}>{Object.translate(item, 'name', this.props.lang)}</li>
                                                                    )
                                                                })
                                                            }


                                                        </div>

                                                        <div className="tabs-content-container">
                                                            <div>
                                                                <Row className="tabs-content tabs-content-reference">
                                                                    {
                                                                        this.state.items && this.state.items.map((item, idx) => {
                                                                            return (
                                                                                <Col lg="4">
                                                                                    <Link to={`/${this.props[0].match.params.menu}/${this.props[0].match.params.category}/${Object.translate(item, 'alias', this.props.lang)}`}>
                                                                                        <ReferenceArticle
                                                                                            refPage={true}
                                                                                            title={Object.translate(item, 'name', this.props.lang)}
                                                                                            shortDescription={Object.translate(item, 'shortDescription', this.props.lang)}
                                                                                            image={item.image}
                                                                                        />
                                                                                    </Link>
                                                                                </Col>
                                                                            )
                                                                        })
                                                                    }

                                                                </Row>
                                                                {
                                                                    this.state.total / 9 > 1 ?
                                                                        <Row>
                                                                            <Col lg="12">
                                                                                <ReactPaginate
                                                                                    previousLabel={''.translate(this.props.lang)}
                                                                                    nextLabel={''.translate(this.props.lang)}
                                                                                    breakLabel={'...'}
                                                                                    breakClassName={'break-me'}
                                                                                    pageCount={this.state.total / 9}
                                                                                    marginPagesDisplayed={1}
                                                                                    pageRangeDisplayed={2}
                                                                                    onPageChange={(page) => { this.updateParam('page', page.selected); window.scrollTo(0, 0); }}
                                                                                    containerClassName={'pagination'}
                                                                                    subContainerClassName={'pages pagination'}
                                                                                    activeClassName={'active'}
                                                                                    hrefBuilder={(page) => { return `?page=${page}` }}
                                                                           
                                                                                />

                                                                            </Col>

                                                                        </Row>

                                                                        :

                                                                        null
                                                                }
                                                            </div>

                                                        </div>
                                                    </Col>
                                                    :
                                                    null
                                            } */}
                                            {
                                                this.state.menuType == 2 && this.state.items && this.state.items[0] ?
                                                    <Col lg="10">
                                                        <Row>
                                                            <Col lg="12">
                                                                <div className="equipment-details-content">
                                                                    <h2>{Object.translate(this.state.items[0], 'name', this.props.lang)}</h2>

                                                                </div>
                                                            </Col>
                                                            {
                                                                this.state.items[0].sections && this.state.items[0].sections.map((item, idx) => {

                                                                    if (item.type == 'media') {
                                                                        return (

                                                                            <Col lg={{ size: item.width.lg }} xs={{ size: item.width.xs }} className="block-section">
                                                                                <img src={API_ENDPOINT + item.value} />
                                                                            </Col>

                                                                        )
                                                                    } else if (item.type == 'text') {
                                                                        return (

                                                                            <Col lg={{ size: item.width.lg }} xs={{ size: item.width.xs }} className="block-section" dangerouslySetInnerHTML={{ __html: Object.translate(item, 'value', this.props.lang) }}>
                                                                            </Col>

                                                                        )
                                                                    }
                                                                })
                                                            }
                                                            {
                                                                this.state.gallery && this.state.gallery.length ?
                                                                    <>
                                                                        <Col lg="12">
                                                                            <div className="article-gallery-title">
                                                                                <h5>{"Foto galerija:".translate(this.props.lang)}</h5>
                                                                            </div>

                                                                        </Col>
                                                                        <div className="article-gallery">
                                                                            {
                                                                                this.state.gallery && this.state.gallery.map((item, idx) => {
                                                                                    return (
                                                                                        <Col lg="4">
                                                                                            <div className="article-gallery-image" onClick={() => {
                                                                                                let index = idx;
                                                                                                this.setState({ lightbox: true, activeIndex: index })
                                                                                            }}
                                                                                                key={idx}>
                                                                                                <img src={API_ENDPOINT + item} />
                                                                                            </div>
                                                                                        </Col>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    null
                                                            }
                                                        </Row>
                                                    </Col>
                                                    :
                                                    null
                                            }


                                        </Row>
                                        {this.state.lightbox ?
                                            <div className="lightbox">
                                                <div className="close" onClick={() => this.setState({ lightbox: null })}>
                                                    <Isvg src={close_ico} />
                                                </div>
                                                <div className="photo-num">
                                                    <h5>{Number(this.state.activeIndex) + 1} / {this.state.galleryLength}</h5>
                                                </div>

                                                <Carousel
                                                    activeIndex={activeIndex}
                                                    next={this.next}
                                                    previous={this.previous}
                                                    autoPlay={null}
                                                >
                                                    {slides}
                                                    <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                                                    <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
                                                </Carousel>

                                            </div>
                                            : null
                                        }
                                    </Container>
                                    {
                                        this.state.menuType == 2 && this.state.items && this.state.items[0] && this.state.items[0].addSection && this.state.items[0].fluidSection ?
                                            <div className="history-fullscreen">
                                                <div className="history-image">
                                                    <img src={API_ENDPOINT + this.state.items[0].fluidSection.image} />
                                                </div>

                                                <Isvg src={ytIcon} onClick={() => this.setState({ activeModalVideo: Object.translate(this.state.items[0].fluidSection, 'promoVideo', this.props.lang) })} />
                                                <div className="history-text">
                                                    <div className="history-text-content">
                                                        <h2>{Object.translate(this.state.items[0].fluidSection, 'title', this.props.lang)}</h2>
                                                        <p>{Object.translate(this.state.items[0].fluidSection, 'shortDescription', this.props.lang)}
                                                        </p>
                                                        <div class="history-fact">
                                                            <Row>
                                                                <Col lg="6">
                                                                    <span>{Object.translate(this.state.items[0].fluidSection, 'number1', this.props.lang)}</span>
                                                                    <p>{Object.translate(this.state.items[0].fluidSection, 'number1text', this.props.lang)}</p>
                                                                </Col>
                                                                <Col lg="6">
                                                                    <span>{Object.translate(this.state.items[0].fluidSection, 'number2', this.props.lang)}</span>
                                                                    <p>{Object.translate(this.state.items[0].fluidSection, 'number2text', this.props.lang)}</p>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        <p>{Object.translate(this.state.items[0].fluidSection, 'detail', this.props.lang)}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            null
                                    }

                                </div>
                                <div className="blog">
                                    <div className="container">
                                        <div className="blog-header">
                                            <div className="blog-title">
                                                <h2>{"Latest news".translate(this.props.lang)}</h2>
                                            </div>
                                            <Link to={this.state.linkAllNews && this.state.linkAllNews[this.props.lang]}><button>{"ALL ARTICLES".translate(this.props.lang)}<span><Isvg src={arrowDark} /></span></button></Link>
                                        </div>
                                        <div className="blog-content">
                                            <Row>
                                                {
                                                    this.state.blogLatest && this.state.blogLatest.map((item, idx) => {
                                                        return (
                                                            <Col lg="4">
                                                                <BlogArticle
                                                                    item={item}
                                                                    image={item.image}
                                                                    title={Object.translate(item, 'name', this.props.lang)}
                                                                    shortDescription={Object.translate(item, 'shortDescription', this.props.lang)}
                                                                    dateTS={item.dateTS}
                                                                    alias={Object.translate(item, 'alias', this.props.lang)}
                                                                    link={Object.translate(item, 'link', this.props.lang)}
                                                                />
                                                            </Col>
                                                        )

                                                    })
                                                }
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                            </>
                            :
                            this.state.menuType == 3 || this.state.menuType == 1 ?
                                <>
                                    <div className="reference-page">
                                        <Container>
                                            <Row>
                                                <Col lg="4">
                                                    <ul className="header-nav agency-nav">
                                                        <div className="agency-nav-header"><span>{this.state.menuName}</span></div>

                                                        {
                                                            this.state.categories && this.state.categories.map((item, idx) => {
                                                                return (
                                                                    <li onClick={() => this.props[0].history.push(`/${this.props[0].match.params.menu}/${Object.translate(item, 'link', this.props.lang)}`)} className={Object.translate(item, 'name', this.props.lang) == this.state.categoryName ? "active-header-nav-item agency-nav-nav-item" : "agency-nav-nav-item"} >
                                                                        <a onClick={() => this.setState({ tab: 0, detailItem: {}, gallery: [], galleryLength: 0 }, () => this.get())}>{Object.translate(item, 'name', this.props.lang)}</a>
                                                                        <ul className="header-nav">
                                                                            {
                                                                                this.state.subcategories && this.state.subcategories.map((subcategory, i) => {
                                                                                    if (subcategory.category == item._id)
                                                                                        return (
                                                                                            <Link to={`/${this.props[0].match.params.menu}/${Object.translate(item, 'link', this.props.lang)}`}>
                                                                                                <li onClick={() => this.setState({ tab: subcategory._id, detailItem: {}, gallery: [], galleryLength: 0 }, () => this.get())}>
                                                                                                    <div className={this.state.tab == subcategory._id ? "active-tab" : ''}>
                                                                                                        <a>{Object.translate(subcategory, 'name', this.props.lang)}</a>
                                                                                                    </div>

                                                                                                </li>
                                                                                            </Link>
                                                                                        )
                                                                                })
                                                                            }

                                                                        </ul>
                                                                    </li>
                                                                )
                                                            })
                                                        }

                                                    </ul>
                                                </Col>
                                                {
                                                    this.state.menuType == 3 ?
                                                        <Col lg="8">
                                                            {
                                                                this.state.detailItem && this.state.detailItem._id ?
                                                                    <Row>
                                                                        <Col lg="12">
                                                                            <div className="history-details-content">
                                                                                <h2>{Object.translate(this.state.detailItem, 'name', this.props.lang)}</h2>
                                                                                <p>{Object.translate(this.state.detailItem, 'shortDescription', this.props.lang)}</p>

                                                                            </div>
                                                                        </Col>

                                                                        {
                                                                            this.state.detailItem.sections && this.state.detailItem.sections.map((item, idx) => {
                                                                                if (item.type == 'media') {
                                                                                    return (

                                                                                        <Col lg={{ size: item.width.lg }} xs={{ size: item.width.xs }} className="block-section">
                                                                                            <img src={API_ENDPOINT + item.value} />
                                                                                        </Col>

                                                                                    )
                                                                                } else if (item.type == 'text') {
                                                                                    return (

                                                                                        <Col lg={{ size: item.width.lg }} xs={{ size: item.width.xs }} className="block-section" dangerouslySetInnerHTML={{ __html: Object.translate(item, 'value', this.props.lang) }}>
                                                                                        </Col>

                                                                                    )
                                                                                } else if (item.type == 'section') {
                                                                                    return (

                                                                                        <Col lg={{ size: item.width.lg }} xs={{ size: item.width.xs }} className="block-section" style={{ backgroundImage: `url(${API_ENDPOINT + item.bgImage})`, objectFit: 'cover' }} dangerouslySetInnerHTML={{ __html: item.value }}>
                                                                                        </Col>


                                                                                    )
                                                                                }
                                                                            })
                                                                        }
                                                                        {
                                                                            this.state.gallery && this.state.gallery.length ?
                                                                                <>
                                                                                    <Col lg="12">
                                                                                        <div className="article-gallery-title">
                                                                                            <h5>{"Foto galerija:".translate(this.props.lang)}</h5>
                                                                                        </div>

                                                                                    </Col>
                                                                                    <div className="article-gallery">
                                                                                        {
                                                                                            this.state.gallery && this.state.gallery.map((item, idx) => {
                                                                                                return (
                                                                                                    <Col lg="4">
                                                                                                        <div className="article-gallery-image2" onClick={() => {
                                                                                                            let index = idx;
                                                                                                            this.setState({ lightbox: true, activeIndex: index })
                                                                                                        }}
                                                                                                            key={idx}>
                                                                                                            <img src={API_ENDPOINT + item} />
                                                                                                        </div>
                                                                                                    </Col>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </div>
                                                                                </>
                                                                                :
                                                                                null
                                                                        }


                                                                    </Row>

                                                                    :
                                                                    null
                                                            }


                                                        </Col>
                                                        :


                                                        this.state.menuType == 1 ?
                                                            <Col lg="8">
                                                                <h2 className="headline" style={{ paddingLeft: 15 }}>{Object.translate(this.state, 'subcategoryName', this.props.lang)}</h2>


                                                                <div className="tabs-content-container">
                                                                    <div>
                                                                        <Row className="tabs-content tabs-content-reference">
                                                                            {
                                                                                this.state.items && this.state.items.map((item, idx) => {
                                                                                    if (item.subcategory == this.state.tab && this.state.tab != 0)
                                                                                        return (
                                                                                            <Col lg="6">
                                                                                                <Link to={`/${this.props[0].match.params.menu}/${this.props[0].match.params.category}/${Object.translate(item, 'alias', this.props.lang)}`}>
                                                                                                    <ReferenceArticle
                                                                                                        refPage={true}
                                                                                                        title={Object.translate(item, 'name', this.props.lang)}
                                                                                                        shortDescription={Object.translate(item, 'shortDescription', this.props.lang)}
                                                                                                        image={item.image}
                                                                                                    />
                                                                                                </Link>
                                                                                            </Col>
                                                                                        )
                                                                                    else if (this.state.tab == 0 && !item.subcategory)
                                                                                        return (
                                                                                            <Col lg="6">
                                                                                                <Link to={`/${this.props[0].match.params.menu}/${this.props[0].match.params.category}/${Object.translate(item, 'alias', this.props.lang)}`}>
                                                                                                    <ReferenceArticle
                                                                                                        refPage={true}
                                                                                                        title={Object.translate(item, 'name', this.props.lang)}
                                                                                                        shortDescription={Object.translate(item, 'shortDescription', this.props.lang)}
                                                                                                        image={item.image}
                                                                                                    />
                                                                                                </Link>
                                                                                            </Col>
                                                                                        )
                                                                                })

                                                                            }

                                                                        </Row>
                                                                        {
                                                                            this.state.total / 9 > 1 ?
                                                                                <Row>
                                                                                    <Col lg="12">
                                                                                        <ReactPaginate
                                                                                            previousLabel={''.translate(this.props.lang)}
                                                                                            nextLabel={''.translate(this.props.lang)}
                                                                                            breakLabel={'...'}
                                                                                            breakClassName={'break-me'}
                                                                                            pageCount={this.state.total / 9}
                                                                                            marginPagesDisplayed={1}
                                                                                            pageRangeDisplayed={2}
                                                                                            onPageChange={(page) => { this.updateParam('page', page.selected); window.scrollTo(0, 0); }}
                                                                                            containerClassName={'pagination'}
                                                                                            subContainerClassName={'pages pagination'}
                                                                                            activeClassName={'active'}
                                                                                            hrefBuilder={(page) => { return `?page=${page}` }}

                                                                                        />

                                                                                    </Col>

                                                                                </Row>

                                                                                :

                                                                                null
                                                                        }
                                                                    </div>

                                                                </div>
                                                            </Col>
                                                            :
                                                            null

                                                }

                                            </Row>
                                            {this.state.lightbox ?
                                                <div className="lightbox">
                                                    <div className="close" onClick={() => this.setState({ lightbox: null })}>
                                                        <Isvg src={close_ico} />
                                                    </div>
                                                    <div className="photo-num">
                                                        <h5>{Number(this.state.activeIndex) + 1} / {this.state.galleryLength}</h5>
                                                    </div>

                                                    <Carousel
                                                        activeIndex={activeIndex}
                                                        next={this.next}
                                                        previous={this.previous}
                                                        autoPlay={null}
                                                    >
                                                        {slides}
                                                        <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                                                        <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
                                                    </Carousel>

                                                </div>
                                                : null
                                            }
                                        </Container>
                                    </div>
                                    {
                                        this.state.tab != 0 && this.state.menuType == 3 ?
                                            <>
                                                <div className="work">
                                                    <div className="container">

                                                        <div className="work-title">
                                                            <h6>{"Radovi koje smo izvodili".translate(this.props.lang)}</h6>
                                                            <h2>{"Reference".translate(this.props.lang)} {Object.translate(this.state.detailItem, 'name', this.props.lang)}</h2>
                                                        </div>



                                                        <div className="tabs-content-container">
                                                            <div>
                                                                <Row className="tabs-content">
                                                                    {
                                                                        this.state.latestReferences && this.state.latestReferences.map((item, idx) => {
                                                                            // if (item.subcategory == this.state.tab)
                                                                            return (
                                                                                <Col lg="4">
                                                                                    <Link to={item.hrefLink}>
                                                                                        <ReferenceArticle
                                                                                            refPage={false}
                                                                                            title={Object.translate(item, 'name', this.props.lang)}
                                                                                            shortDescription={Object.translate(item, 'shortDescription', this.props.lang)}
                                                                                            image={item.image}
                                                                                            subcategoryName={Object.translate(this.state.detailItem, 'name', this.props.lang)}
                                                                                        />
                                                                                    </Link>
                                                                                </Col>
                                                                            )
                                                                        })
                                                                    }

                                                                </Row>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="help-section">
                                                    <Link to={Object.translate(this.state, 'linkToReference', this.props.lang)} params={{ tab: this.state.tab }}>{"Sve reference".translate(this.props.lang)}<span><Isvg src={arrowDark} /></span> </Link>
                                                </div>
                                            </>
                                            :
                                            null
                                    }

                                    < div className="blog">
                                        <div className="container">
                                            <div className="blog-header">
                                                <div className="blog-title">
                                                    <h2>{"Latest news".translate(this.props.lang)}</h2>
                                                </div>
                                                <Link to={this.state.linkAllNews && this.state.linkAllNews[this.props.lang]}><button>{"ALL ARTICLES".translate(this.props.lang)}<span><Isvg src={arrowDark} /></span></button></Link>
                                            </div>
                                            <div className="blog-content">
                                                <Row>
                                                    {
                                                        this.state.blogLatest && this.state.blogLatest.map((item, idx) => {
                                                            return (
                                                                <Col lg="4">
                                                                    <BlogArticle
                                                                        item={item}
                                                                        image={item.image}
                                                                        title={Object.translate(item, 'name', this.props.lang)}
                                                                        shortDescription={Object.translate(item, 'shortDescription', this.props.lang)}
                                                                        dateTS={item.dateTS}
                                                                        alias={Object.translate(item, 'alias', this.props.lang)}
                                                                        link={Object.translate(item, 'link', this.props.lang)}
                                                                    />
                                                                </Col>
                                                            )

                                                        })
                                                    }
                                                </Row>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                :
                                this.state.menuType == 4 ?
                                    <div>
                                        <div className="blog-section">
                                            <Container>
                                                <Row>
                                                    <Col lg="12">
                                                        <div className="blog-title">
                                                            <h1>{"Poslednje novosti".translate(this.props.lang)}</h1>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    {
                                                        this.state.items && this.state.items.map((item, idx) => {
                                                            return (
                                                                <Col lg="4">
                                                                    {/* <Link to={`/${this.props[0].match.params.menu}/${this.props[0].match.params.category}/${Object.translate(item, 'alias', this.props.lang)}`}> */}
                                                                    <BlogArticle
                                                                        item={item}
                                                                        image={item.image}
                                                                        title={Object.translate(item, 'name', this.props.lang)}
                                                                        shortDescription={Object.translate(item, 'shortDescription', this.props.lang)}
                                                                        dateTS={item.dateTS}
                                                                        alias={Object.translate(item, 'alias', this.props.lang)}
                                                                        link={`/${this.props[0].match.params.menu}/${this.props[0].match.params.category}`}
                                                                    />
                                                                    {/* </Link> */}
                                                                </Col>
                                                            )

                                                        })
                                                    }



                                                </Row>
                                                {
                                                    this.state.total / 15 > 1 ?
                                                        <Row>
                                                            <Col lg="12">
                                                                <ReactPaginate
                                                                    previousLabel={''.translate(this.props.lang)}
                                                                    nextLabel={''.translate(this.props.lang)}
                                                                    breakLabel={'...'}
                                                                    breakClassName={'break-me'}
                                                                    pageCount={this.state.total / 15}
                                                                    marginPagesDisplayed={1}
                                                                    pageRangeDisplayed={2}
                                                                    onPageChange={(page) => { this.updateParam('page', page.selected); window.scrollTo(0, 0); }}
                                                                    // onPageChange={(page) => { this.props[0].history.push(this.generateSearchLink('page', page.selected)) }}
                                                                    containerClassName={'pagination'}
                                                                    subContainerClassName={'pages pagination'}
                                                                    activeClassName={'active'}
                                                                    hrefBuilder={(page) => { return `?page=${page}` }}
                                                                // hrefBuilder={(page) => { return this.generateSearchLink('page', page) }}
                                                                //forcePage={this.state.page}
                                                                />

                                                            </Col>

                                                        </Row>

                                                        :

                                                        null
                                                }
                                            </Container>
                                        </div>

                                    </div>
                                    :
                                    this.state.menuType == 5 ?
                                        <>
                                            <div className="archive">
                                                <Container>
                                                    <Row>
                                                        <Col lg="12">
                                                            <div className="archive-section-title">
                                                                <h2>{"Arhiva dokumenata".translate(this.props.lang)}</h2>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row style={{ marginBottom: 45 }}>
                                                        <Col lg="12">
                                                            <div className="archive-title">
                                                                <h6>{this.state.categoryName}</h6>
                                                            </div>
                                                        </Col>
                                                        <Col lg="12">
                                                            <Row className="archive-wrap">
                                                                {
                                                                    this.state.items && this.state.items.map((item, idx) => {
                                                                        return (
                                                                            <Col lg="6">
                                                                                <a href={API_ENDPOINT + item.image} download={Object.translate(item, 'name', this.props.lang)} target="_blank">
                                                                                    <div className="document">
                                                                                        <img src={item.format == 'pdf' ? pdf : item.format == 'exel' ? xx : item.format == 'word' ? word : null} alt="" />
                                                                                        <h6>{Object.translate(item, 'name', this.props.lang)}</h6>
                                                                                    </div>
                                                                                </a>
                                                                            </Col>
                                                                        )

                                                                    })
                                                                }

                                                            </Row>
                                                        </Col>
                                                    </Row>


                                                </Container>
                                            </div>

                                            <div className="blog">
                                                <div className="container">
                                                    <div className="blog-header">
                                                        <div className="blog-title">
                                                            <h2>{"Latest news".translate(this.props.lang)}</h2>
                                                        </div>
                                                        <Link to={this.state.linkAllNews && this.state.linkAllNews[this.props.lang]}><button>{"ALL ARTICLES".translate(this.props.lang)}<span><Isvg src={arrowDark} /></span></button></Link>
                                                    </div>
                                                    <div className="blog-content">
                                                        <Row>
                                                            {
                                                                this.state.blogLatest && this.state.blogLatest.map((item, idx) => {
                                                                    return (
                                                                        <Col lg="4">
                                                                            <BlogArticle
                                                                                item={item}
                                                                                image={item.image}
                                                                                title={Object.translate(item, 'name', this.props.lang)}
                                                                                shortDescription={Object.translate(item, 'shortDescription', this.props.lang)}
                                                                                dateTS={item.dateTS}
                                                                                alias={Object.translate(item, 'alias', this.props.lang)}
                                                                                link={Object.translate(item, 'link', this.props.lang)}
                                                                            />
                                                                        </Col>
                                                                    )

                                                                })
                                                            }
                                                        </Row>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        :

                                        null
                    }

                </div>

                {
                    this.state.activeModalVideo ?
                        <Modal isOpen={this.state.activeModalVideo} className="modal-video" centered={true} size="xl">
                            <ModalHeader toggle={() => this.setState({ activeModalVideo: !this.state.activeModalVideo })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ activeModalVideo: !this.state.activeModalVideo })}>&times;</button>}>{'Promo video'.translate(this.props.lang)}</ModalHeader>

                            <ModalBody>
                                <div className='promo-video'>
                                    <YouTube videoId={this.state.activeModalVideo} opts={opts} onReady={this._onReady} />
                                </div>
                            </ModalBody>
                        </Modal>
                        :
                        null
                }

            </div >


        )
    }
}

export default Page(ReferencePage);