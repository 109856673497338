import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { API_ENDPOINT } from '../constants';
import blogImage from '../assets/images/b1.jpg';

import { NavItem } from 'reactstrap';

export class BlogArticle extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {
        return (

            <Link to={this.props.link + '/' + this.props.alias}>
                <article className="article">
                    <div className="article-header">
                        <img src={API_ENDPOINT + this.props.image} />
                    </div>
                    <div className="article-body">
                        <h6>
                        {
                                    this.props.title && this.props.title.length > 100 ?
                                        this.props.title.substr(0, 97) + '...'
                                    :
                                        this.props.title
                                }
                        </h6>
                        <span>{moment.unix(this.props.dateTS).format("DD.MM.YYYY.  |  HH:mm")}h</span>
                    </div>
                    <div className="article-body-on-hover">
                        <h6>
                        {
                                    this.props.title && this.props.title.length > 50 ?
                                        this.props.title.substr(0, 47) + '...'
                                    :
                                        this.props.title
                                }
                        </h6>
                        <p>
                        {
                                    this.props.shortDescription && this.props.shortDescription.length > 80 ?
                                        this.props.shortDescription.substr(0, 77) + '...'
                                    :
                                        this.props.shortDescription
                                }
                        </p>
                        <span>{moment.unix(this.props.dateTS).format("DD.MM.YYYY.  |  HH:mm")}h</span>
                    </div>
                </article>
            </Link>
        )
    }
}

export default BlogArticle;