import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';


import {
    Container,
    Row,
    Col,
    Navbar,
    NavbarBrand,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Nav, NavItem,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption

} from 'reactstrap';

import facebook_icon from '../assets/svg/facebook-icon.svg'
import instagram_icon from '../assets/svg/instagram-icon.svg'
import phone_icon from '../assets/svg/header-phone.svg'
import mail_icon from '../assets/svg/header-mail.svg'

import hamburger_icon from '../assets/svg/hamburger-icon.svg'

import logo from '../assets/logo.svg';
import noImage from '../assets/no-image.jpg';
import zajednica from '../assets/zajednica.svg';
import arrow_black from '../assets/arrow-black.svg';
import profile from '../assets/profile.png';
import moment from 'moment';






class HomeHeader extends Component {
    constructor(props) {
        super(props);
        this.registerSocketIOEvents = this.registerSocketIOEvents.bind(this);
        this.suggestions = this.suggestions.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.state = {
            suggestions: [],
            search: '',
            imageErrors: {},
            width: 0, height: 0,
            showSearch: false,
            today: moment().format('dddd, DD.MM.YYYY.')
        };

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);


    }





    componentDidMount() {
        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }
        document.addEventListener('mousedown', this.handleClickOutside);
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);


      


    }

    componentDidUpdate(prevProps) {
        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }


    }

    registerSocketIOEvents() {
        if (this.state._registeredEvents)
            return;

        this.setState({
            _registeredEvents: true
        });

        this.props.socketIOClient.on('suggestions', (data) => {

            this.setState({
                imageErrors: {},
                suggestions: data.products
            })
        });



    }
    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
        window.removeEventListener('resize', this.updateWindowDimensions);

        if (!this.props.socketIOClient) return;
        this.props.socketIOClient.removeAllListeners("suggestions");
        //this.props.socketIOClient.removeAllListeners("fetchProductVariations");

    }


    suggestions() {
        if (this.state.search.length) {
            this.props.socketIOClient.emit("suggestions", { search: this.state.search });
        }
    }
    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({
                suggestions: []
            })
        }

        if (this.leftMenuRef && !this.leftMenuRef.contains(event.target)) {
            this.setState({
                leftMenu: null
            })
        }

        /*if (this.rightMenuRef && !this.rightMenuRef.contains(event.target)) {
            this.setState({
                rightMenu: null
            })
        }*/
    }
    logout = () => {



    }


    render() {
        return (
            <div>
                <Container>
                    <Row>
                        <Col lg="12">
                            <header>
                                
                            </header>

                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}


export default HomeHeader;
