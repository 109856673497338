import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import Isvg from 'react-inlinesvg';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    UncontrolledDropdown,
    Input
} from 'reactstrap';

import Page from '../containers/page';
import pdfIcon from '../assets/svg/pdf.svg'
import blogImage from '../assets/images/b1.jpg';
import arrowRight from '../assets/svg/arrow-white.svg';
import BlogArticle from '../components/blogArticle';
import arrowDark from '../assets/svg/arrow-right.svg';

import pdf from '../assets/pdf.png';
import word from '../assets/word.png';
import xx from '../assets/xx.png';

import { API_ENDPOINT } from '../constants';

export class archivePage extends Component {
    constructor(props) {
        super(props);
        this.updateStateFromSearch = this.updateStateFromSearch.bind(this);
        this.updateParam = this.updateParam.bind(this);
        this.state = {
            promotedProjects: [],
            page: 0,
            ...props.initialData
        };
    }

    updateStateFromSearch(callback) {
        let broken = this.props[0].location.search.split('?').pop().split('&');
        let params = { page: 0 };
        for (let i = 0; i < broken.length; i++) {
            if (broken[i].indexOf('=') !== -1) {
                params[broken[i].split('=')[0]] = broken[i].split('=')[1];
            }
        }

        this.setState({
            category: null,
            tag: null,
            items: []
        }, () => {
            this.setState(params, callback);

        })

    }
    componentDidMount() {

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                })
            })
        }

        // this.get()

        // this.updateStateFromSearch(this.get);


    }


    updateParam(name, value) {

        let broken = this.props[0].location.search.split('?').pop().split('&');
        let params = {};
        for (let i = 0; i < broken.length; i++) {
            if (broken[i].indexOf('=') !== -1) {
                params[broken[i].split('=')[0]] = broken[i].split('=')[1];
            }
        }

        params[name] = value;

        let paramsArr = [];
        for (var key in params) {
            if (params.hasOwnProperty(key) && params[key]) {

                paramsArr.push(key + "=" + params[key]);
            }
        }



        let search = '?' + paramsArr.join('&');


        this.props[0].history.push(this.props[0].location.pathname + search);
        ////console.log(search);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.updateStateFromSearch(() => {
                this.get()
            });
        }
        if (prevProps[0].location.pathname != this.props[0].location.pathname) {
            this.get()
        }
    }


    render() {
        return (
            <div>

                <div className="header">
                    <div className="header-content">
                        <Container>
                            <Row>
                                <Col lg="6" sm="6">
                                    <h1 className="breadcrumb-title">Arhiva</h1>
                                </Col>
                                <Col lg="6" sm="6">
                                    <span className="breadcrumb">
                                        <a>Početna</a>
                                        //
                                        <a>Arhiva</a>
                                    </span>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>

                <div className="archive">
                    <Container>
                        <Row>
                            <Col lg="12">
                                <div className="archive-section-title">
                                    <h2>Arhiva dokumenata</h2>
                                </div>
                            </Col>
                        </Row>
                        <Row style={{ marginBottom: 45 }}>
                            <Col lg="12">
                                <div className="archive-title">
                                    <h6>Opšti dokumenti</h6>
                                </div>
                            </Col>
                            <Col lg="12">
                                <Row className="archive-wrap">
                                    {
                                        this.state.documents && this.state.documents.map((item, idx) => {
                                            if (item.category == 1)
                                                return (
                                                    <Col lg="6">
                                                        <a href={API_ENDPOINT + item.icon} download={item.name} target="_blank">
                                                            <div className="document">
                                                                <img src={item.format == 'pdf' ? pdf : item.format == 'exel' ? xx : item.format == 'word' ? word : null} alt="" />
                                                                <h6>{item.name}</h6>
                                                            </div>
                                                        </a>
                                                    </Col>
                                                )

                                        })
                                    }
                                
                                </Row>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg="12">
                                <div className="archive-title">
                                    <h6>Kadrovska</h6>
                                </div>
                            </Col>
                            <Col lg="12">
                                <Row className="archive-wrap">
                                    {
                                        this.state.documents && this.state.documents.map((item, idx) => {
                                            if (item.category == 2)
                                                return (
                                                    <Col lg="6">
                                                        <a href={API_ENDPOINT + item.icon} download={item.name} target="_blank">
                                                            <div className="document">
                                                                <img  src={item.format == 'pdf' ? pdf : item.format == 'exel' ? xx : item.format == 'word' ? word : null} alt="" />
                                                                <h6>{item.name}</h6>
                                                            </div>
                                                        </a>
                                                    </Col>
                                                )

                                        })
                                    }
                                    
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <div className="blog">
                    <div className="container">
                        <div className="blog-header">
                            <div className="blog-title">
                                <h2>Latest news</h2>
                            </div>
                            <Link to={this.state.linkAllNews && this.state.linkAllNews[this.props.lang]}><button>ALL ARTICLES<span><Isvg src={arrowDark} /></span></button></Link>
                        </div>
                        <div className="blog-content">
                            <Row>
                            {
                                    this.state.blogLatest && this.state.blogLatest.map((item, idx) => {
                                        return (
                                            <Col lg="4">
                                                <BlogArticle
                                                    item={item}
                                                    image={item.image}
                                                    title={item.name}
                                                    shortDescription={item.shortDescription}
                                                    dateTS={item.dateTS}
                                                    alias={item.alias}
                                                />
                                            </Col>
                                        )

                                    })
                                }
                            </Row>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default Page(archivePage);