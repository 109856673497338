import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import Isvg from 'react-inlinesvg';

import moment from 'moment';
import { API_ENDPOINT } from '../constants';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    UncontrolledDropdown,
    Input
} from 'reactstrap';

import Page from '../containers/page';
import BlogArticle from '../components/blogArticle';
import blogDetails from '../assets/images/blog-details.jpg';

export class BlogDetalsPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData
        };
    }

    componentDidMount() {

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get()



    }

    get = () => {
        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                }, () => this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang)))
            })
        }


    }
    componentDidUpdate(prevProps) {
        if (prevProps[0].location.pathname != this.props[0].location.pathname) {
            if (typeof window !== 'undefined') { window.scrollTo(0, 0); }
            this.get()


            for (let i = 0; i < this.props.loadData.length; i++) {
                this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                    this.setState({
                        ...data
                    })
                })
            }
        }



    }


    render() {
        return (
            <div>

                <div className="header">
                    <div className="header-content">
                        <Container>
                            <Row>
                                <Col lg="6" sm="6">
                                    <h1 className="breadcrumb-title">{'Pretraga'.translate(this.props.lang)}</h1>
                                </Col>
                                <Col lg="6" sm="6">
                                    <span className="breadcrumb">
                                        <Link to="/">{"Početna".translate(this.props.lang)}</Link>
                                        //
                                        <a>{'Pretraga'.translate(this.props.lang)}</a>
                                    </span>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>

                <div className="blog-details-container">
                    <Container>
                        <Row>
                            <Col lg="8">
                                <div className="main-article">
                                    <h1>{this.state.data && this.state.data.name}</h1>
                                    <span>{moment.unix(this.state.data && this.state.data.dateTS).format("DD.MM.YYYY.  |  HH:mm")}h</span>
                                    <h6>{this.state.data && this.state.data.shortDescription}</h6>
                                    <img src={API_ENDPOINT + (this.state.data && this.state.data.image)} />
                                    {/* <div dangerouslySetInnerHTML={{ __html: this.state.data && this.state.data.content }}></div> */}

                                </div>
                                {
                                    this.state.data && this.state.data.sections && this.state.data.sections.map((item, idx) => {
                                        if (item.type == 'media') {
                                            return (

                                                <Col lg={{ size: item.width.lg }} xs={{ size: item.width.xs }} className="block-section">
                                                    <img src={API_ENDPOINT + item.value} />
                                                </Col>

                                            )
                                        } else if (item.type == 'text') {
                                            return (

                                                <Col lg={{ size: item.width.lg }} xs={{ size: item.width.xs }} className="block-section" dangerouslySetInnerHTML={{ __html: Object.translate(item, 'value', this.props.lang) }}>
                                                </Col>

                                            )
                                        } else if (item.type == 'section') {
                                            return (

                                                <Col lg={{ size: item.width.lg }} xs={{ size: item.width.xs }} className="block-section" style={{ backgroundImage: `url(${API_ENDPOINT + item.bgImage})`, objectFit: 'cover' }} dangerouslySetInnerHTML={{ __html: item.value }}>
                                                </Col>


                                            )
                                        }
                                    })
                                }
                            </Col>
                            <Col lg="4">
                                <div className="sidebar">
                                    <h2>Poslednje novosti</h2>
                                    {
                                        this.state.blogLatest && this.state.blogLatest.map((item, idx) => {
                                            return (
                                                <BlogArticle
                                                    item={item}
                                                    image={item.image}
                                                    title={item.name}
                                                    shortDescription={item.shortDescription}
                                                    dateTS={item.dateTS}
                                                    alias={item.alias}
                                                    link={Object.translate(item, 'link', this.props.lang)}
                                                />
                                            )

                                        })
                                    }

                                    {/* <BlogArticle />
                                    <BlogArticle />
                                    <BlogArticle /> */}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>

            </div>
        )
    }
}

export default Page(BlogDetalsPage);