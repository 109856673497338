import React, { Component } from 'react';
// import Link from '../components/link';

import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';
import Page from '../containers/page';

import aboutImage from '../assets/images/about.jpg';
import arrowLight from '../assets/svg/arrow-white.svg';
import arrowDark from '../assets/svg/arrow-right.svg';
import serviceIcon1 from '../assets/svg/s1.svg';
import serviceIcon2 from '../assets/svg/s2.svg';
import serviceIcon4 from '../assets/svg/s4.svg';
import featuresIcon1 from '../assets/svg/feature-1.svg';
import featuresIcon2 from '../assets/svg/feature-2.svg';
import ytIcon from '../assets/svg/yt-icon.svg';
import video from '../assets/images/video.jpg';
import sf1 from '../assets/images/sf1.jpg';
import sf2 from '../assets/images/sf2.jpg';

import moment from 'moment';
import { API_ENDPOINT } from '../constants';
import HomeSlider from '../components/slider';
import ReferenceArticle from '../components/referenceArticle';
import BlogArticle from '../components/blogArticle';
import YouTube from 'react-youtube';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    UncontrolledDropdown,
    Input,
    Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';



var striptags = require('striptags');

class HomePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            promotedProjects: [],
            poolActive: false,
            tab: 0,

            ...props.initialData
        };
    }

    componentDidMount() {

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }




        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                })
            })
        }
        this.get();

        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
    }
    get = () => {



        // fetch(API_ENDPOINT + '/services/random/' + this.props.lang, {
        //     method: 'GET',
        //     headers: {
        //         'Content-Type': 'application/json',

        //     }
        // }).then((res) => res.json()).then((result) => {
        //     this.setState({
        //         services: result.item,
        //         allServicesLink: result.allServicesLink
        //     })
        // })
        // fetch(API_ENDPOINT + '/referemces/home/' + this.props.lang, {
        //     method: 'GET',
        //     headers: {
        //         'Content-Type': 'application/json',

        //     }
        // }).then((res) => res.json()).then((result) => {
        //     this.setState({
        //         references: result.items,
        //         allReferencesLink: result.allReferencesLink,
        //         categories: result.categories
        //     }, () => {
        //         if (this.state.tab == 0 && this.state.categories && this.state.categories.length) {
        //             this.setState({ tab: this.state.categories[0]._id })
        //         }
        //     })
        // })

    }
    componentDidUpdate(prevProps) {
        if (prevProps[0].location.pathname != this.props[0].location.pathname) {
            // this.get()
        }
    }


    render() {
        // const opts = {

        //     height: typeof window !== 'undefined' && window.innerWidth > 1120 ? '600' : typeof window !== 'undefined' && window.innerWidth > 700 ? '400' : '195',
        //     width: typeof window !== 'undefined' && window.innerWidth > 1120 ? '1110' : typeof window !== 'undefined' && window.innerWidth > 700 ? '620' : '300',
        //     playerVars: {
        //         // https://developers.google.com/youtube/player_parameters
        //         autoplay: 0,
        //     },
        // };


        return (
            <div>

                <div className="header">
                    <div className="header-content">
                        <Container>
                            <Row>
                                <Col lg="6">
                                    <h1 className="breadcrumb-title">{"FORTIS Group".translate(this.props.lang)}</h1>
                                </Col>
                                <Col lg="6">
                                    <span className="breadcrumb">
                                        <Link to="/">{"Početna".translate(this.props.lang)}</Link>
                                    //
                                    <a>{"FORTIS Group".translate(this.props.lang)}</a>
                                    </span>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>

                <div className="gallery" style={{ paddingBottom: 0 }}>
                    <Container className="fortis-first-section" fluid>
                        <Container>
                            <Row>
                                <Col lg="12">
                                    <div className="section-title">
                                        {/* <h6>{"Firma i oprema".translate(this.props.lang)}</h6> */}
                                        <h2>{"FORTIS GROUP".translate(this.props.lang)}</h2>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="12">
                                    <Row>
                                        <Col lg="12">
                                            <div className="equipment-details-content">
                                                <h2>{Object.translate(this.state.data, 'title', this.props.lang)}</h2>

                                            </div>
                                        </Col>

                                    </Row>
                                </Col>
                            </Row>

                        </Container>


                    </Container>
                    {
                        this.state.data && this.state.data.sections && this.state.data.sections.length ?
                            <Container className="fortis-first-section" fluid>
                                <Container>
                                    <Row>
                                        {
                                            this.state.data.sections.map((item, idx) => {

                                                if (item.type == 'media') {
                                                    return (

                                                        <Col lg={{ size: item.width.lg }} xs={{ size: item.width.xs }} className="block-section">
                                                            <img src={API_ENDPOINT + item.value} />
                                                        </Col>

                                                    )
                                                } else if (item.type == 'text') {
                                                    return (

                                                        <Col lg={{ size: item.width.lg }} xs={{ size: item.width.xs }} className="block-section" dangerouslySetInnerHTML={{ __html: Object.translate(item, 'value', this.props.lang) }}>
                                                        </Col>

                                                    )
                                                }
                                            })
                                        }
                                    </Row>
                                </Container>
                            </Container>
                            :
                            null
                    }
                    {
                        this.state.data && this.state.data.sections2 && this.state.data.sections2.length ?
                            <Container className="fortis-second-section" fluid>
                                <Container>
                                    <Row>
                                        {
                                            this.state.data.sections2.map((item, idx) => {

                                                if (item.type == 'media') {
                                                    return (

                                                        <Col lg={{ size: item.width.lg }} xs={{ size: item.width.xs }} className="block-section">
                                                            <img src={API_ENDPOINT + item.value} />
                                                        </Col>

                                                    )
                                                } else if (item.type == 'text') {
                                                    return (

                                                        <Col lg={{ size: item.width.lg }} xs={{ size: item.width.xs }} className="block-section" dangerouslySetInnerHTML={{ __html: Object.translate(item, 'value', this.props.lang) }}>
                                                        </Col>

                                                    )
                                                }
                                            })
                                        }
                                    </Row>
                                </Container>
                            </Container>
                            :
                            null

                    }
                    {
                        this.state.data && this.state.data.sections3 && this.state.data.sections3.length ?

                            <Container className="fortis-third-section" fluid>
                                <Container>
                                    <Row>
                                        {
                                            this.state.data.sections3.map((item, idx) => {

                                                if (item.type == 'media') {
                                                    return (

                                                        <Col lg={{ size: item.width.lg }} xs={{ size: item.width.xs }} className="block-section">
                                                            <img src={API_ENDPOINT + item.value} />
                                                        </Col>

                                                    )
                                                } else if (item.type == 'text') {
                                                    return (

                                                        <Col lg={{ size: item.width.lg }} xs={{ size: item.width.xs }} className="block-section" dangerouslySetInnerHTML={{ __html: Object.translate(item, 'value', this.props.lang) }}>
                                                        </Col>

                                                    )
                                                }
                                            })
                                        }
                                    </Row>
                                </Container>

                            </Container>
                            :
                            null
                    }

                </div>

                <div className="blog">
                    <div className="container">
                        <div className="blog-header">
                            <div className="blog-title">
                                <h2>{"Latest news".translate(this.props.lang)}</h2>
                            </div>
                            <Link to={this.state.linkAllNews && this.state.linkAllNews[this.props.lang]}><button>{"ALL ARTICLES".translate(this.props.lang)}<span><Isvg src={arrowDark} /></span></button></Link>
                        </div>
                        <div className="blog-content">
                            <Row>
                                {
                                    this.state.blogLatest && this.state.blogLatest.map((item, idx) => {
                                        return (
                                            <Col lg="4">
                                                <BlogArticle
                                                    item={item}
                                                    image={item.image}
                                                    title={Object.translate(item, 'name', this.props.lang)}
                                                    shortDescription={Object.translate(item, 'shortDescription', this.props.lang)}
                                                    dateTS={item.dateTS}
                                                    alias={Object.translate(item, 'alias', this.props.lang)}
                                                    link={Object.translate(item, 'link', this.props.lang)}
                                                />
                                            </Col>
                                        )

                                    })
                                }
                            </Row>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default Page(HomePage);