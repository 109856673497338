export const langs = {
    "ba": {
        "Unesite naslov Vaše poruke": "Unesite naslov Vaše poruke",
        "Usluge koje pružamo": "Usluge koje pružamo",
        "Naše usluge": "Naše usluge",
        "Usluge transporta koje nudimo": "Usluge transporta koje nudimo",
        "KOMPANIJA OSNOVANA": "KOMPANIJA OSNOVANA",
        "ZEMALJA POSLUJEMO": "ZEMALJA POSLUJEMO",
        "POŠILJKI DOSTAVLJENO": "POŠILJKI DOSTAVLJENO",
        "Aktuelnosti": "Aktuelnosti",
        "Novosti": "Novosti",
        "i obavještenja": "i obavještenja",
        "Aktuelnosti vezane za našu firmu": "Aktuelnosti vezane za našu firmu",
        "Telefoni:": "Telefoni:",
        "Radno vrijeme:": "Radno vrijeme:",
        "Detaljnije": "Detaljnije",
        "O nama": "O nama",
        "Početna": "Početna",
        "Usluge": "Usluge",
        "Navigacija": "Navigacija",
        "Kontakt": "Kontakt",
        "Budite slobodni da nas kontaktirate. Odgovorićemo Vam u što kraćem roku.": "Budite slobodni da nas kontaktirate. Odgovorićemo Vam u što kraćem roku.",
        "INFORMACIJE": "INFORMACIJE",
        "Top TLS d.o.o. korisni podaci": "Top TLS d.o.o. korisni podaci",
        "ID broj": "ID broj",
        "PDV broj:": "PDV broj:",
        "Registracija:": "Registracija:",
        "Općinski sud u Sarajevu": "Općinski sud u Sarajevu",
        "Transakcijski račun – UniCredit bank": "Transakcijski račun – UniCredit bank",
        "Devizni račun (IBAN) – UniCredit bank": "Devizni račun (IBAN) – UniCredit bank",
        "Devizni račun (SWIFT):": "Devizni račun (SWIFT):",
        "Ime": "Ime",
        "Prezime": "Prezime",
        "Telefon": "Telefon",
        "E-mail": "E-mail",
        "Naslov": "Naslov",
        "Vaša poruka": "Vaša poruka",
        "Kontaktirajte nas" : "Kontaktirajte nas",
        "Budite slobodni da nas kontaktirate u bilo koje vrijeme." : "Budite slobodni da nas kontaktirate u bilo koje vrijeme.",
        "Naša adresa" : "Naša adresa",
        "Naši telefoni" : "Naši telefoni",
        "E-mail adresa" : "E-mail adresa",
        "Vaše ime" : "Vaše ime",
        "Unesite Vaše puno ime" : "Unesite Vaše puno ime",
        "Vaš telefon" : "Vaš telefon",
        "Unesite broj telefona" : "Unesite broj telefona",
        "Vaš e-mail" : "Vaš e-mail",
        "Unesite Vašu E-mail adresu" : "Unesite Vašu E-mail adresu",
        "Unesite Vašu poruku..." : "Unesite Vašu poruku...",
        "POŠALJI UPIT" : "POŠALJI UPIT",
        "Galerija" : "Galerija",
        "Firma i oprema" : "Firma i oprema",
        "Galerija fotografija" : "Galerija fotografija",
        "Latest news" : "Poslednje novosti",
        "ALL ARTICLES" : "SVE VIJESTI",
        "One year on from our ambition launch, Bernard looks back" : "",
        "DETALJNIJE" : "DETALJNIJE",
        "Sa nama ste uvijek na dobrom putu" : "Sa nama ste uvijek na dobrom putu",
        "Oil Services" : "Naftne usluge",
        "Our Services" : "Naše usluge",
        "Usluge koje pruzamo" : "Usluge koje pruzamo",
        "SVE USLUGE" : "SVE USLUGE",
        "Radovi koje smo izvodili" : "Radovi koje smo izvodili",
        "Naše rerefence" : "Naše rerefence",
        "Sve reference" : "Sve reference",
        "Reportaza" : "Reportaza",
        "Promo video" : "Promo video",
        "Poslednje novosti" : "Poslednje novosti",
        "Reference" : "Reference",
        "Arhiva dokumenata" : "Arhiva dokumenata",
        "Liknovi" : "Liknovi",
        "Sign up to oue nwsletter to stay up to date with the latest news and updates from IOGCE." : "Pretplatite se na naš NEWSLETTER",
        "E-mail adress" : "E-mail adresa",
        "Foto galerija:" : "Foto galerija:",

    },
    "en": {
        "Unesite naslov Vaše poruke": "Enter the subject of your message",
        "Usluge koje pružamo": "The services we provide",
        "Naše usluge": "Our services",
        "Usluge transporta koje nudimo": "Transport services we offer",
        "KOMPANIJA OSNOVANA": "COMPANY ESTABLISHED",
        "ZEMALJA POSLUJEMO": "COUNTRIES WE BUSINESS",
        "POŠILJKI DOSTAVLJENO": "SHIPMENTS DELIVERED",
        "Aktuelnosti": "Current news",
        "Novosti": "News",
        "i obavještenja": "and announcements",
        "Aktuelnosti vezane za našu firmu": "News related to our company",
        "Telefoni:": "Telephones:",
        "Radno vrijeme:": "Working hours:",
        "Detaljnije": "More details",
        "O nama": "About Us",
        "Početna": "Home",
        "Usluge": "Services",
        "Uvoz vozila": "Import of vehicles",
        "Navigacija": "Navigation",
        "Kontakt": "Contact",
        "Vaš upit": "Your inquiry",
        "Budite slobodni da nas kontaktirate. Odgovorićemo Vam u što kraćem roku.": "Feel free to contact us. We will answer you as soon as possible.",
        "INFORMACIJE": "INFORMATION",
        "Top TLS d.o.o. korisni podaci": "Top TLS d.o.o. useful data",
        "ID broj": "ID number",
        "PDV broj:": "VAT number:",
        "Registracija:": "Registration:",
        "Općinski sud u Sarajevu": "Municipal Court in Sarajevo",
        "Transakcijski račun – UniCredit bank": "Transaction account - UniCredit bank",
        "Devizni račun (IBAN) – UniCredit bank": "Foreign currency account (IBAN) - UniCredit bank",
        "Devizni račun (SWIFT):": "Foreign currency account (SWIFT):",
        "Ime": "Name",
        "Prezime": "Surname",
        "Telefon": "Phone",
        "E-mail": "E-mail",
        "Naslov": "Title",
        "Vaša poruka": "Your message",
        "POŠALJI": "SEND",
        "Vaša poruka je poslata, očekujte odgovor ubrzo.": "Your message has been sent, expect a reply soon.",
        "Kontaktirajte nas" : "Contact us",
        "Budite slobodni da nas kontaktirate u bilo koje vrijeme.": "Feel free to contact us at any time.",
        "Naša adresa" : "Our address",
        "Naši telefoni" : "Our phones",
        "E-mail adresa" : "E-mail address",
        "Vaše ime" : "Your name",
        "Unesite Vaše puno ime" : "Enter your full name",
        "Vaš telefon" : "Your phone",
        "Unesite broj telefona" : "Enter the phone number",
        "Vaš e-mail" : "Your e-mail",
        "Unesite Vašu E-mail adresu" : "Enter your E-mail address",
        "Unesite Vašu poruku..." : "Enter your message ...",
        "POŠALJI UPIT" : "SEND",
        "Galerija" : "Gallery",
        "Firma i oprema" : "Company and equipment",
        "Galerija fotografija" : "Photo Gallery",
        "Latest news" : "Latest news",
        "ALL ARTICLES" : "ALL ARTICLES",
        "One year on from our ambition launch, Bernard looks back" : "",
        "DETALJNIJE" : "MORE DETAILS",
        "Sa nama ste uvijek na dobrom putu" : "You are always on the right track with us",
        "Oil Services" : "Oil Services",
        "Our Services" : "Our Services",
        "Usluge koje pruzamo" : "The services we provide",
        "SVE USLUGE" : "ALL SERVICES",
        "Radovi koje smo izvodili" : "The works we performed",
        "Naše rerefence" : "Our references",
        "Sve reference" : "All references",
        "Reportaza" : "Report",
        "Promo video" : "Promo video",
        "Poslednje novosti": "Latest news",
        "Reference" : "References",
        "Arhiva dokumenata" : "Document archive",
        "Liknovi" : "Links",
        "Sign up to oue nwsletter to stay up to date with the latest news and updates from IOGCE." : "Subscribe to our NEWSLETTER",
        "E-mail adress" : "E-mail address",
        "Foto galerija:" : "Photo gallery:"
    },
    "de": {
        "Unesite naslov Vaše poruke": "Geben Sie den Betreff Ihrer Nachricht ein",
        "Usluge koje pružamo": "Die von uns angebotenen Dienstleistungen",
        "Naše usluge": "Unsere Dienstleistungen",
        "Usluge transporta koje nudimo": "Transportdienstleistungen, die wir anbieten",
        "KOMPANIJA OSNOVANA": "UNTERNEHMEN GEGRÜNDET",
        "ZEMALJA POSLUJEMO": "LÄNDER, DIE WIR UNTERNEHMEN",
        "POŠILJKI DOSTAVLJENO": "VERSAND GELIEFERT",
        "Aktuelnosti": "Aktuelle Meldungen",
        "Novosti": "Nachrichten",
        "i obavještenja": "und Ankündigungen",
        "Aktuelnosti vezane za našu firmu": "Neuigkeiten zu unserem Unternehmen",
        "Telefoni:": "Telefone:",
        "Radno vrijeme:": "Arbeitszeit:",
        "Detaljnije": "Mehr Details",
        "O nama": "Über uns",
        "Početna": "Startseite",
        "Usluge": "Dienstleistungen",
        "Uvoz vozila": "Import von Fahrzeugen",
        "Navigacija": "Navigation",
        "Kontakt": "Kontakt",
        "Vaš upit": "Deine Anfrage",
        "Budite slobodni da nas kontaktirate. Odgovorićemo Vam u što kraćem roku.": "Fühlen Sie sich frei uns zu kontaktieren. Wir werden Ihnen so schnell wie möglich antworten.",
        "INFORMACIJE": "INFORMATION",
        "Top TLS d.o.o. korisni podaci": "Top TLS d.o.o. useful data",
        "ID broj": "ID number",
        "PDV broj:": "VAT number:",
        "Registracija:": "Anmeldung:",
        "Općinski sud u Sarajevu": "Municipal Court in Sarajevo",
        "Transakcijski račun – UniCredit bank": "Transaction account - UniCredit bank",
        "Devizni račun (IBAN) – UniCredit bank": "Foreign currency account (IBAN) - UniCredit bank",
        "Devizni račun (SWIFT):": "Foreign currency account (SWIFT):",
        "Ime": "Name",
        "Prezime": "Nachname",
        "Telefon": "Telefon",
        "E-mail": "E-mail",
        "Naslov": "Titel",
        "Vaša poruka": "Ihre Nachricht",
        "POŠALJI": "SENDEN",
        "Vaša poruka je poslata, očekujte odgovor ubrzo.": "Ihre Nachricht wurde gesendet, erwarten Sie bald eine Antwort.",
        "Kontaktirajte nas" : "Kontaktiere uns",
        "Budite slobodni da nas kontaktirate u bilo koje vrijeme.": "Sie können uns jederzeit kontaktieren.",
        "Naša adresa" : "Unsere Adresse",
        "Naši telefoni" : "Unsere Telefone",
        "E-mail adresa" : "E-mailadressen",
        "Vaše ime" : "Dein Name",
        "Unesite Vaše puno ime" : "Geben Sie Ihren vollständigen Namen ein",
        "Vaš telefon" : "Dein Telefon",
        "Unesite broj telefona" : "Geben Sie die Telefonnummer ein",
        "Vaš e-mail" : "Deine E-Mail",
        "Unesite Vašu E-mail adresu" : "Geben sie ihre E-Mailadresse ein",
        "Unesite Vašu poruku..." : "Gib deine Nachricht ein ...",
        "POŠALJI UPIT" : "SENDEN",
        "Galerija" : "Galerie",
        "Firma i oprema" : "Firma und Ausrüstung",
        "Galerija fotografija" : "Fotogallerie",
        "Latest news" : "Neuesten Nachrichten",
        "ALL ARTICLES" : "ALLE ARTIKEL",
        "One year on from our ambition launch, Bernard looks back" : "",
        "DETALJNIJE" : "MEHR DETAILS",
        "Sa nama ste uvijek na dobrom putu" : "Mit uns sind Sie immer auf dem richtigen Weg",
        "Oil Services" : "Öldienstleistungen",
        "Our Services" : "Unsere Dienstleistungen",
        "Usluge koje pruzamo" : "Die Dienstleistungen, die wir anbieten",
        "SVE USLUGE" : "ALLE DIENSTLEISTUNGEN",
        "Radovi koje smo izvodili" : "Die Arbeiten, die wir durchgeführt haben",
        "Naše rerefence" : "Unsere Referenzen",
        "Sve reference" : "Alle Referenzen",
        "Reportaza" : "Bericht",
        "Promo video" : "Promo video",
        "Poslednje novosti": "Neuesten Nachrichten",
        "Reference" : "Verweise",
        "Arhiva dokumenata" : "Dokumentenarchiv",
        "Liknovi" : "Links",
        "Sign up to oue nwsletter to stay up to date with the latest news and updates from IOGCE." : "Abonnieren Sie unseren Newsletter",
        "E-mail adress" : "E-Mail-Addresse",
        "Foto galerija:" : "Fotogallerie:"
    }
}