import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import Isvg from 'react-inlinesvg';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    UncontrolledDropdown,
    Input
} from 'reactstrap';

import Page from '../containers/page';
import BlogArticle from '../components/forms/searchArticle';

import blogImage from '../assets/images/b1.jpg';

import moment from 'moment';
import { API_ENDPOINT } from '../constants';

export class BlogPage extends Component {
    constructor(props) {
        super(props);
        this.updateStateFromSearch = this.updateStateFromSearch.bind(this);
        this.updateParam = this.updateParam.bind(this);
        this.get = this.get.bind(this);

        this.state = {
            total: 0,
            items: [],
            promotedProjects: [],

            ...props.initialData
        };
    }

    updateStateFromSearch(callback) {
        let broken = this.props[0].location.search.split('?').pop().split('&');
        let params = { page: 0 };
        for (let i = 0; i < broken.length; i++) {
            if (broken[i].indexOf('=') !== -1) {
                params[broken[i].split('=')[0]] = broken[i].split('=')[1];
            }
        }

        this.setState({
            category: null,
            tag: null,
            items: []
        }, () => {
            this.setState(params, callback);

        })

    }


    updateParam(name, value) {

        let broken = this.props[0].location.search.split('?').pop().split('&');
        let params = {};
        for (let i = 0; i < broken.length; i++) {
            if (broken[i].indexOf('=') !== -1) {
                params[broken[i].split('=')[0]] = broken[i].split('=')[1];
            }
        }

        params[name] = value;

        let paramsArr = [];
        for (var key in params) {
            if (params.hasOwnProperty(key) && params[key]) {

                paramsArr.push(key + "=" + params[key]);
            }
        }



        let search = '?' + paramsArr.join('&');


        this.props[0].history.push(this.props[0].location.pathname + search);
        ////console.log(search);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.updateStateFromSearch(() => {
                this.get()
            });
        }
        if (prevProps[0].location.pathname != this.props[0].location.pathname) {
            this.get()
        }
    }

    componentDidMount() {

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                })
            })
        }



        this.updateStateFromSearch(this.get);


    }
    get() {

        this.setState({
            formLoading: true,
            // errorMessage: null
        }, () => {

            fetch(API_ENDPOINT + '/search/site', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',

                },
                body: JSON.stringify({
                    search: this.props[0].match.params.search
                })
            }).then((res) => res.json()).then((result) => {
                this.setState({
                    formDone: true,
                    search: ''
                })
                if (result) {
                    for (let i = 0; i < result.items.length; i++) {
                        result.items[i].dateTS = new Date(result.items[i].date + " " + result.items[i].time).getTime() / 1000;
                    }
                    this.setState({
                        total: result.total,
                        items: result.items
                    })
                }
            })
        })


    }


    render() {
        return (
            <div>

                <div className="header">
                    <div className="header-content">
                        <Container>
                            <Row>
                                <Col lg="6" sm="6">
                                    <h1 className="breadcrumb-title">{'Pretraga'.translate(this.props.lang)}</h1>
                                </Col>
                                <Col lg="6" sm="6">
                                    <span className="breadcrumb">
                                        <Link to="/">{"Početna".translate(this.props.lang)}</Link>
                                        //
                                        <a>{'Pretraga'.translate(this.props.lang)}</a>
                                    </span>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>

                <div>
                    <div className="blog-section">
                        <Container>
                            <Row>
                                <Col lg="12">
                                    <div className="blog-title">
                                        <h1>{"Ukupno rezultata".translate(this.props.lang)}: {this.state.total} - {'za'.translate(this.props.lang)} "{this.props[0].match.params.search}"</h1>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                {
                                    this.state.items && this.state.items.map((item, idx) => {
                                        if (item.menuType != 5)
                                            return (
                                                <Col lg="4">
                                                    <BlogArticle
                                                        item={item}
                                                        image={item.image}
                                                        title={Object.translate(item, 'name', this.props.lang)}
                                                        shortDescription={Object.translate(item, 'shortDescription', this.props.lang)}
                                                        dateTS={item.dateTS}
                                                        alias={Object.translate(item, 'alias', this.props.lang)}
                                                        link={Object.translate(item, 'link', this.props.lang)}
                                                        menuType={item.menuType}
                                                        subcategory={item.subcategory ? item.subcategory : 0}
                                                    />
                                                </Col>
                                            )

                                    })
                                }



                            </Row>
                        </Container>
                    </div>
                </div>

            </div>
        )
    }
}

export default Page(BlogPage);