import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import Isvg from 'react-inlinesvg';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    UncontrolledDropdown,
    Input
} from 'reactstrap';

import Page from '../containers/page';
import sf1 from '../assets/images/sf1.jpg';
import arrowRight from '../assets/svg/arrow-white.svg';
import blogImage from '../assets/images/b1.jpg';
import ReferenceArticle from '../components/referenceArticle';
import arrowDark from '../assets/svg/arrow-right.svg';
import BlogArticle from '../components/blogArticle';

import moment from 'moment';
import { API_ENDPOINT } from '../constants';

export class ReferencePage extends Component {
    constructor(props) {
        super(props);
        this.updateStateFromSearch = this.updateStateFromSearch.bind(this);
        this.updateParam = this.updateParam.bind(this);
        this.get = this.get.bind(this);

        this.state = {
            promotedProjects: [],
            tab: 1,
            ...props.initialData
        };
    }

    updateStateFromSearch(callback) {
        let broken = this.props[0].location.search.split('?').pop().split('&');
        let params = { page: 0 };
        for (let i = 0; i < broken.length; i++) {
            if (broken[i].indexOf('=') !== -1) {
                params[broken[i].split('=')[0]] = broken[i].split('=')[1];
            }
        }

        this.setState({
            category: null,
            tag: null,
            items: []
        }, () => {
            this.setState(params, callback);

        })

    }


    updateParam(name, value) {

        let broken = this.props[0].location.search.split('?').pop().split('&');
        let params = {};
        for (let i = 0; i < broken.length; i++) {
            if (broken[i].indexOf('=') !== -1) {
                params[broken[i].split('=')[0]] = broken[i].split('=')[1];
            }
        }

        params[name] = value;

        let paramsArr = [];
        for (var key in params) {
            if (params.hasOwnProperty(key) && params[key]) {

                paramsArr.push(key + "=" + params[key]);
            }
        }



        let search = '?' + paramsArr.join('&');


        this.props[0].history.push(this.props[0].location.pathname + search);
        ////console.log(search);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.updateStateFromSearch(() => {
                this.get()
            });
        }
        if (prevProps[0].location.pathname != this.props[0].location.pathname) {
            this.get()
        }
    }

    componentDidMount() {

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                })
            })
        }



        this.updateStateFromSearch(this.get);


    }
    get() {

        fetch(API_ENDPOINT + `/references/${this.props[0].match.params.category}`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                page: this.state.page,
                lang: this.props.lang
            })
        }).then(res => res.json()).then((result) => {
            for (let i = 0; i < result.items.length; i++) {
                result.items[i].dateTS = new Date(result.items[i].date + " " + result.items[i].time).getTime() / 1000;
            }
            this.setState({
                items: result.items,
                total: result.total,
            }, () => this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang)))
        })


    }


    render() {
        return (
            <div>

                <div className="header">
                    <div className="header-content">
                        <Container>
                            <Row>
                                <Col lg="6" sm="6">
                                    <h1 className="breadcrumb-title">Reference</h1>
                                </Col>
                                <Col lg="6" sm="6">
                                    <span className="breadcrumb">
                                        <a>Početna</a>
                                        //
                                        <a>Reference</a>
                                    </span>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>

                <div className="reference-page">
                    <Container>
                        <Row>
                            <Col lg="2">
                                <ul className="header-nav">
                                    <span>Reference</span>
                                    <li style={{ borderTop: 0 }} className={this.props[0].location.pathname == '/reference/proizvodnja' ? 'active' : ''}>
                                        <Link to="/reference/proizvodnja">Proizvodnja</Link>
                                    </li>
                                    <li className={this.props[0].location.pathname == '/reference/odrzavanje' ? 'active' : ''}>
                                        <Link to="/reference/odrzavanje">Odrzavanje</Link>
                                    </li>
                                    <li className={this.props[0].location.pathname == '/reference/izgradnja' ? 'active' : ''}>
                                        <Link to="/reference/izgradnja">Izgradnja</Link>
                                    </li>
                                </ul>
                            </Col>
                            <Col lg="10">
                                <h2 className="headline" style={{ paddingLeft: 15 }}>Odrzavanje</h2>
                                <div className="tabs tabs-dark tabs-reference">
                                    <li className={this.state.tab == 1 ? "active-tab" : ''} onClick={() => { this.setState({ tab: 1 }) }}>Rehabilitacija puteva</li>
                                    <li className={this.state.tab == 2 ? "active-tab" : ''} onClick={() => { this.setState({ tab: 2 }) }}>Horizontalna i vertikalna signalizacija</li>
                                    <li className={this.state.tab == 3 ? "active-tab" : ''} onClick={() => { this.setState({ tab: 3 }) }}>Uklanjanje vegetacije</li>
                                    <li className={this.state.tab == 4 ? "active-tab" : ''} onClick={() => { this.setState({ tab: 4 }) }}>Odrzavanje ostalihi elemenata puta i objekata na putevima</li>
                                </div>

                                <div className="tabs-content-container">
                                    <div>
                                        <Row className="tabs-content tabs-content-reference">
                                            {
                                                this.state.items && this.state.items.map((item, idx) => {
                                                    if(item.subcategory == this.state.tab)
                                                    return (
                                                        <Col lg="4">
                                                            <Link to="">
                                                                <ReferenceArticle 
                                                                    refPage={true}
                                                                    title={item.name}
                                                                    shortDescription={item.shortDescription}
                                                                    image={item.image}
                                                                />
                                                            </Link>
                                                        </Col>
                                                    )
                                                })
                                            }
                                            {/* <Col lg="4">
                                                <Link to="">
                                                    <ReferenceArticle />
                                                </Link>
                                            </Col>
                                            <Col lg="4">
                                                <Link to="">
                                                    <ReferenceArticle />
                                                </Link>
                                            </Col>
                                            <Col lg="4">
                                                <Link to="">
                                                    <ReferenceArticle />
                                                </Link>
                                            </Col>
                                            <Col lg="4">
                                                <Link to="">
                                                    <ReferenceArticle />
                                                </Link>
                                            </Col>
                                            <Col lg="4">
                                                <Link to="">
                                                    <ReferenceArticle />
                                                </Link>
                                            </Col>
                                            <Col lg="4">
                                                <Link to="">
                                                    <ReferenceArticle />
                                                </Link>
                                            </Col> */}
                                        </Row>
                                    </div>
                                    <div>Content of second tab</div>
                                    <div>Content of third tab</div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <div className="blog">
                    <div className="container">
                        <div className="blog-header">
                            <div className="blog-title">
                                <h2>Latest news</h2>
                            </div>
                            <Link to={this.state.linkAllNews && this.state.linkAllNews[this.props.lang]}><button>ALL ARTICLES<span><Isvg src={arrowDark} /></span></button></Link>
                        </div>
                        <div className="blog-content">
                            <Row>
                                {
                                    this.state.blogLatest && this.state.blogLatest.map((item, idx) => {
                                        return (
                                            <Col lg="4">
                                                <BlogArticle
                                                    item={item}
                                                    image={item.image}
                                                    title={item.name}
                                                    shortDescription={item.shortDescription}
                                                    dateTS={item.dateTS}
                                                    alias={item.alias}
                                                    link={Object.translate(item, 'link', this.props.lang)}
                                                />
                                            </Col>
                                        )

                                    })
                                }
                            </Row>
                        </div>
                    </div>
                </div>

            </div>


        )
    }
}

export default Page(ReferencePage);