import React from 'react';



export default class Map extends React.Component {
    constructor(props) {
        super(props);
        this.initMap = this.initMap.bind(this);
        this.state = {};
    }

    initMap() {
        if (typeof window == 'undefined') {
            return;
        }

        this.setState({
            _mapInit: true
        });
        var latLng = new window.google.maps.LatLng(44.7400705, 19.2025375
        );



        var map = new window.google.maps.Map(this.GoogleMap, {
            zoom: 16,
            center: latLng,
            mapTypeId: window.google.maps.MapTypeId.ROADMAP,
            disableDefaultUI: true,
            gestureHandling: "gestureHandling",

        });


        var marker = new window.google.maps.Marker({
            position: latLng,
            map: map,
        });
        window.google.maps.event.addListener(marker, 'click', function () {
            if (typeof window != 'undefined')
                window.open("https://maps.google.com?q=44.7400705,19.2025375");
        });

    }

    componentDidMount() {
        if (this.props._googleMapsLoaded && !this.state._mapInit) {
            this.initMap();
        }

    }

    componentDidUpdate() {
        if (this.props._googleMapsLoaded && !this.state._mapInit) {
            this.initMap();
        }
    }

    render() {
        return (
            <div className="map-wrap">

                {
                    this.props._googleMapsLoaded ?
                        <div className="map" ref={(input) => { this.GoogleMap = input; }}>

                        </div>
                        : null
                }
            </div>
        );
    }
}
