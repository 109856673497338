import React, { Component } from 'react';
// import Link from '../components/link';

import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';
import Page from '../containers/page';

import aboutImage from '../assets/images/about.jpg';
import arrowLight from '../assets/svg/arrow-white.svg';
import arrowDark from '../assets/svg/arrow-right.svg';
import serviceIcon1 from '../assets/svg/s1.svg';
import serviceIcon2 from '../assets/svg/s2.svg';
import serviceIcon4 from '../assets/svg/s4.svg';
import featuresIcon1 from '../assets/svg/feature-1.svg';
import featuresIcon2 from '../assets/svg/feature-2.svg';
import ytIcon from '../assets/svg/yt-icon.svg';
import video from '../assets/images/video.jpg';
import sf1 from '../assets/images/sf1.jpg';
import sf2 from '../assets/images/sf2.jpg';

import moment from 'moment';
import { API_ENDPOINT } from '../constants';
import HomeSlider from '../components/slider';
import ReferenceArticle from '../components/referenceArticle';
import BlogArticle from '../components/blogArticle';
import YouTube from 'react-youtube';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    UncontrolledDropdown,
    Input,
    Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';



var striptags = require('striptags');

class HomePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            promotedProjects: [],
            poolActive: false,
            tab: 0,

            ...props.initialData
        };
    }

    componentDidMount() {

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }




        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                })
            })
        }
        this.get();

        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
    }
    get = () => {



        fetch(API_ENDPOINT + '/services/random/' + this.props.lang, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',

            }
        }).then((res) => res.json()).then((result) => {
            this.setState({
                services: result.item,
                allServicesLink: result.allServicesLink
            })
        })
        fetch(API_ENDPOINT + '/referemces/home/' + this.props.lang, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',

            }
        }).then((res) => res.json()).then((result) => {
            this.setState({
                references: result.items,
                allReferencesLink: result.allReferencesLink,
                categories: result.categories
            }, () => {
                if (this.state.tab == 0 && this.state.categories && this.state.categories.length) {
                    this.setState({ tab: this.state.categories[0]._id })
                }
            })
        })

    }
    componentDidUpdate(prevProps) {
        if (prevProps[0].location.pathname != this.props[0].location.pathname) {
            // this.get()
        }
    }


    render() {
        const opts = {

            height: typeof window !== 'undefined' && window.innerWidth > 1120 ? '600' : typeof window !== 'undefined' && window.innerWidth > 700 ? '400' : '195',
            width: typeof window !== 'undefined' && window.innerWidth > 1120 ? '1110' : typeof window !== 'undefined' && window.innerWidth > 700 ? '620' : '300',
            playerVars: {
                // https://developers.google.com/youtube/player_parameters
                autoplay: 0,
            },
        };

{/*if(typeof window == 'undefined') {
    return null
}*/}
        return (
            <div>

                <div className="home">
                    <div className="dark-upper-layer"></div>
                    <div className="home-container">
                        {
                            this.state.home && this.state.home.slide && this.state.home.slide.length ?
                                <HomeSlider slides={this.state.home.slide} lang={this.props.lang} />
                                :
                                null
                        }

                    </div>
                </div>

                <div className="about">
                    <Container>
                        {
                            this.state.home && this.state.home.section && this.state.home.section[0] ?
                                <Row>
                                    <Col lg="7">
                                        <div className="about-text">
                                            <span>Bijeljina Put d.o.o.</span>
                                            <h2>{Object.translate(this.state.home.section[0], 'name', this.props.lang)}</h2>
                                            <div dangerouslySetInnerHTML={{ __html: Object.translate(this.state.home.section[0], 'shortDescription', this.props.lang) }}></div>
                                            <Link to={Object.translate(this.state.home.section[0], 'link', this.props.lang)}> <button>
                                                {"DETALJNIJE".translate(this.props.lang)}
                                                <span>
                                                    <Isvg src={arrowLight} />
                                                </span>
                                            </button></Link>
                                        </div>
                                    </Col>
                                    <Col lg="5">
                                        <div className="about-image">
                                            <img src={API_ENDPOINT + this.state.home.section[0].image} />
                                            {/* <div className="shape"></div> */}
                                        </div>
                                    </Col>
                                </Row>
                                :
                                null
                        }

                    </Container>
                </div>


                <div className="service">
                    <Container style={{ padding: 0 }}>
                        <Row>
                            <Col lg="4">
                                <div className="service-text">
                                    <h2> <span>Bijeljina put</span> <br /> FORTIS Group</h2>
                                    <p>{"Sa nama ste uvijek na dobrom putu".translate(this.props.lang)}  </p>
                                </div>
                            </Col>
                            <Col lg="8">
                                <Row>
                                    {
                                        this.state.home && this.state.home.info && this.state.home.info.map((item, idx) => {
                                            if (Object.translate(item, 'name', this.props.lang) && Object.translate(item, 'shortDescription', this.props.lang))
                                                return (
                                                    <Col lg="6" sm="6">
                                                        <div className="service-block mb-55">
                                                            <img src={API_ENDPOINT + item.image} />
                                                            <div className="service-block-footer">
                                                                <h5>{Object.translate(item, 'name', this.props.lang)}</h5>
                                                                <p>{Object.translate(item, 'shortDescription', this.props.lang)}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                )
                                        })
                                    }

                                </Row>
                            </Col>
                        </Row>

                        <div className="service-highlight-container">
                            {
                                this.state.home && this.state.home.infoImage1 ?
                                    <div className="service-highlight">
                                        <img src={API_ENDPOINT + this.state.home.infoImage1} />
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.home && this.state.home.infoImage2 ?
                                    <div className="service-highlight">
                                        <img src={API_ENDPOINT + this.state.home.infoImage2} />
                                    </div>
                                    :
                                    null
                            }

                        </div>

                    </Container>
                </div>

                <div className="offer home-offer">
                    <Container>
                        <Row>
                            <Col lg="12">
                                <div className="offer-title">
                                    <h2>{"Usluge koje pružamo".translate(this.props.lang)}</h2>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            {
                                this.state.services && this.state.services.map((item, idx) => {
                                    return (
                                        <Col lg="4">
                                            <Link to={{
                                                pathname: `${Object.translate(item, 'link', this.props.lang)}`,
                                                state: { tab: item.subcategory }
                                            }}>
                                                <ReferenceArticle
                                                    refPage={false}
                                                    title={Object.translate(item, 'name', this.props.lang)}
                                                    shortDescription={Object.translate(item, 'shortDescription', this.props.lang)}
                                                    image={item.image}
                                                    subcategoryName={Object.translate(item, 'categoryName', this.props.lang)}
                                                />
                                            </Link>
                                        </Col>
                                    )
                                })
                            }


                        </Row>
                        <Row>
                            <Col lg="12">
                                <div className="offer-cta">
                                    <Link to={Object.translate(this.state, 'allServicesLink', this.props.lang)}>
                                        {"SVE USLUGE".translate(this.props.lang)}
                                        <Isvg src={arrowLight} />
                                    </Link>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <div className="work work-home">
                    <div className="container">

                        <div className="work-title">
                            <h6>{"Radovi koje smo izvodili".translate(this.props.lang)}</h6>
                            <h2>{"Naše reference".translate(this.props.lang)}</h2>
                        </div>

                        <ul className="tabs tabs-light">
                            {
                                this.state.categories && this.state.categories.map((item, idx) => {
                                    return (
                                        <li className={item._id == this.state.tab ? "active-tab" : ""} onClick={() => this.setState({ tab: item._id })}>{Object.translate(item, 'name', this.props.lang)}</li>
                                    )
                                })
                            }


                        </ul>

                        <div className="tabs-content-container">
                            <div>
                                <Row className="tabs-content">
                                    {
                                        this.state.references && this.state.references.map((item, idx) => {
                                            if (this.state.tab == item.category)
                                                return (
                                                    <Col lg="4">
                                                        <Link to={`${Object.translate(item, 'link', this.props.lang)}/${Object.translate(item, 'alias', this.props.lang)}`}>
                                                            <ReferenceArticle
                                                                refPage={false}
                                                                title={Object.translate(item, 'name', this.props.lang)}
                                                                shortDescription={Object.translate(item, 'shortDescription', this.props.lang)}
                                                                image={item.image}
                                                                subcategoryName={Object.translate(item, 'subcategoryName', this.props.lang)}
                                                            />
                                                        </Link>
                                                    </Col>
                                                )
                                        })
                                    }
                                </Row>
                            </div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </div>

                <div className="help-section">
                    <Link to={Object.translate(this.state, 'allReferencesLink', this.props.lang)}>
                        {"Sve reference".translate(this.props.lang)}
                        <span>
                            <Isvg src={arrowDark} />
                        </span>
                    </Link>
                </div>


                <div className="video">
                    <div className="container">
                        <div className="video-title">
                            <h6>{"Reportaža".translate(this.props.lang)}</h6>
                            <h2>{"Promo video".translate(this.props.lang)}</h2>
                        </div>
                        <div className="video-content">
                            {
                                this.state.home && this.state.home.promoVideo ?
                                    <div className='promo-video'>
                                        <YouTube videoId={this.state.home.promoVideo} opts={opts} onReady={this._onReady} />
                                    </div>
                                    :
                                    null
                            }
                            {/* <img src={video} />
                            <Isvg src={ytIcon} /> */}
                        </div>
                    </div>
                </div>

                <div className="blog blog-big">
                    <div className="container">
                        <div className="blog-header">
                            <div className="blog-title">
                                <h2>{"Latest news".translate(this.props.lang)}</h2>
                            </div>
                            <Link to={this.state.linkAllNews && this.state.linkAllNews[this.props.lang]}><button>{"ALL ARTICLES".translate(this.props.lang)}<span><Isvg src={arrowDark} /></span></button></Link>
                        </div>
                        <div className="blog-content">
                            <Row>
                                {
                                    this.state.blogLatest && this.state.blogLatest.map((item, idx) => {
                                        return (
                                            <Col lg="4">
                                                <BlogArticle
                                                    item={item}
                                                    image={item.image}
                                                    title={Object.translate(item, 'name', this.props.lang)}
                                                    shortDescription={Object.translate(item, 'shortDescription', this.props.lang)}
                                                    dateTS={item.dateTS}
                                                    alias={Object.translate(item, 'alias', this.props.lang)}
                                                    link={Object.translate(item, 'link', this.props.lang)}
                                                />
                                            </Col>
                                        )

                                    })
                                }
                            </Row>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default Page(HomePage);