import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import Isvg from 'react-inlinesvg';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    UncontrolledDropdown,
    Input
} from 'reactstrap';

import Page from '../containers/page';

import galleryImage1 from '../assets/images/g1.jpg';
import galleryImage2 from '../assets/images/g2.jpg';
import galleryImage3 from '../assets/images/g3.jpg';
import galleryImage4 from '../assets/images/g4.jpg';
import galleryImage5 from '../assets/images/g5.jpg';
import galleryImage6 from '../assets/images/g6.jpg';
import galleryImage7 from '../assets/images/g7.jpg';

import close_ico from '../assets/svg/close-ico.svg';

import BlogArticle from '../components/blogArticle';
import arrowDark from '../assets/svg/arrow-right.svg';

import { API_ENDPOINT } from '../constants';

export class GalleryPage extends Component {
    constructor(props) {
        super(props);
        this.updateStateFromSearch = this.updateStateFromSearch.bind(this);
        this.updateParam = this.updateParam.bind(this);
        this.get = this.get.bind(this);

        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);

        this.state = {
            promotedProjects: [],
            page: 0,
            gallery: [],
            activeIndex: 0,
            ...props.initialData
        };
    }

    updateStateFromSearch(callback) {
        let broken = this.props[0].location.search.split('?').pop().split('&');
        let params = { page: 0 };
        for (let i = 0; i < broken.length; i++) {
            if (broken[i].indexOf('=') !== -1) {
                params[broken[i].split('=')[0]] = broken[i].split('=')[1];
            }
        }

        this.setState({
            category: null,
            tag: null,
            items: []
        }, () => {
            this.setState(params, callback);

        })

    }


    updateParam(name, value) {

        let broken = this.props[0].location.search.split('?').pop().split('&');
        let params = {};
        for (let i = 0; i < broken.length; i++) {
            if (broken[i].indexOf('=') !== -1) {
                params[broken[i].split('=')[0]] = broken[i].split('=')[1];
            }
        }

        params[name] = value;

        let paramsArr = [];
        for (var key in params) {
            if (params.hasOwnProperty(key) && params[key]) {

                paramsArr.push(key + "=" + params[key]);
            }
        }



        let search = '?' + paramsArr.join('&');


        this.props[0].history.push(this.props[0].location.pathname + search);
        ////console.log(search);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.updateStateFromSearch(() => {
                this.get()
            });
        }
        if (prevProps[0].location.pathname != this.props[0].location.pathname) {
            this.get()
        }
    }

    componentDidMount() {

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                })
            })
        }
        
        this.get()

        this.updateStateFromSearch(this.get);


    }
    get() {

        fetch(`https://bijeljinaput-api.novamedia.agency/gallery`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                page: this.state.page,
                lang: this.props.lang
            })
        }).then(res => res.json()).then((result) => {

            this.setState({
                gallery: result.items,
                total: result.total,
            }, () => this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang)))
        })

    }

    onExiting() {
        this.animating = true;
    }

    onExited() {
        this.animating = false;
    }

    next() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === this.state.gallery.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    }

    previous() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? this.state.gallery.length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    }

    goToIndex(newIndex) {
        if (this.animating) return;
        this.setState({ activeIndex: newIndex });
    }



    render() {
        const { activeIndex } = this.state;
        const slides = this.state.gallery && this.state.gallery.map((item) => {
            return (
                <CarouselItem
                    tag="div"
                    key={item}
                    onExiting={this.onExiting}
                    onExited={this.onExited}
                >
                    <div className="lightbox-item">
                        <img src={API_ENDPOINT + item} />
                    </div>

                </CarouselItem>
            );
        });
        return (
            <div>

                <div className="header">
                    <div className="header-content">
                        <Container>
                            <Row>
                                <Col lg="6">
                                    <h1 className="breadcrumb-title">{"Galerija".translate(this.props.lang)}</h1>
                                </Col>
                                <Col lg="6">
                                    <span className="breadcrumb">
                                    <Link to="/">{"Početna".translate(this.props.lang)}</Link>
                                        //
                                        <a>{"Galerija".translate(this.props.lang)}</a>
                                    </span>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>

                <div className="gallery">
                    <Container>
                        <Row>
                            <Col lg="12">
                                <div className="section-title">
                                    {/*<h6>{"Firma i oprema".translate(this.props.lang)}</h6>*/}
                                    <h2>{"Galerija fotografija".translate(this.props.lang)}</h2>
                                </div>
                            </Col>
                        </Row>
                        {/* <Row className="gallery-row-1">
                            <Col lg="4">
                                <img src={galleryImage1} />
                            </Col>
                            <Col lg="4">
                                <img src={galleryImage2} />
                            </Col>
                            <Col lg="4">
                                <img src={galleryImage3} />
                            </Col>
                        </Row>
                        <Row className="gallery-row-2">
                            <Col lg="6">
                                <img src={galleryImage4} />
                            </Col>
                            <Col lg="6">
                                <img src={galleryImage5} />
                            </Col>
                        </Row>
                        <Row className="gallery-row-3">
                            <Col lg="4">
                                <img src={galleryImage6} />
                            </Col>
                            <Col lg="4">
                                <img src={galleryImage7} />
                            </Col>
                            <Col lg="4">
                                <img src={galleryImage2} />
                            </Col>
                        </Row>
                        <Row className="gallery-row-4">
                            <Col lg="6">
                                <img src={galleryImage4} />
                            </Col>
                            <Col lg="6">
                                <img src={galleryImage5} />
                            </Col>
                        </Row> */}
                        <Row className="gallery-row-1">
                            {
                                this.state.gallery && this.state.gallery.map((item, idx) => {
                                    if((idx + 1) % 5 == 1 || (idx + 1) % 5 == 2 || (idx + 1) % 5 == 3)
                                    return (
                                        <Col lg="4">
                                            <div className="gallery-image1" onClick={() => {
                                                let index = idx;
                                                this.setState({ lightbox: true, activeIndex: index })
                                            }}
                                                key={idx}>
                                                <img src={API_ENDPOINT + item} />
                                            </div>
                                        </Col>
                                    )
                                    else{
                                        return (
                                            <Col lg="6">
                                                <div className="gallery-image2" onClick={() => {
                                                    let index = idx;
                                                    this.setState({ lightbox: true, activeIndex: index })
                                                }}
                                                    key={idx}>
                                                    <img src={API_ENDPOINT + item} />
                                                </div>
                                            </Col>
                                        )
                                    }
                                })
                            }
                        </Row>


                    </Container>
                    {this.state.lightbox ?
                        <div className="lightbox">
                            <div className="close" onClick={() => this.setState({ lightbox: null })}>
                                <Isvg src={close_ico} />
                            </div>
                            <div className="photo-num">
                                <h5>{Number(this.state.activeIndex) + 1} / {this.state.total}</h5>
                            </div>

                            <Carousel
                                activeIndex={activeIndex}
                                next={this.next}
                                previous={this.previous}
                                autoPlay={null}
                            >
                                {slides}
                                <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                                <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
                            </Carousel>

                        </div>
                        : null
                    }
                </div>

                <div className="blog">
                    <div className="container">
                        <div className="blog-header">
                            <div className="blog-title">
                                <h2>{"Latest news".translate(this.props.lang)}</h2>
                            </div>
                            <Link to={this.state.linkAllNews && this.state.linkAllNews[this.props.lang]}><button>{"ALL ARTICLES".translate(this.props.lang)}<span><Isvg src={arrowDark} /></span></button></Link>
                        </div>
                        <div className="blog-content">
                            <Row>
                            {
                                    this.state.blogLatest && this.state.blogLatest.map((item, idx) => {
                                        return (
                                            <Col lg="4">
                                                <BlogArticle
                                                    item={item}
                                                    image={item.image}
                                                    title={Object.translate(item, 'name', this.props.lang)}
                                                    shortDescription={Object.translate(item, 'shortDescription', this.props.lang)}
                                                    dateTS={item.dateTS}
                                                    alias={Object.translate(item, 'alias', this.props.lang)}
                                                    link={Object.translate(item, 'link', this.props.lang)}
                                                />
                                            </Col>
                                        )

                                    })
                                }
                            </Row>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default Page(GalleryPage);
