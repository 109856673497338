

import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import Isvg from 'react-inlinesvg';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,

} from 'reactstrap';

import logo from '../assets/svg/logo.svg';
import arrowDown from '../assets/svg/arrow-down.svg';
import arrowDownDark from '../assets/svg/arrow-down-dark.svg';
//import menuIcon from '../assets/svg/menu.svg';
import searchIcon from '../assets/svg/search.svg';
import fbIcon from '../assets/svg/i-fb.svg';
import instIcon from '../assets/svg/i-inst.svg';
import ytIcon from '../assets/svg/i-yt.svg';
import liIcon from '../assets/svg/i-ln.svg';
import bars from '../assets/svg/bars.svg';
import arrow from '../assets/svg/nsl-arrow.svg';

import { API_ENDPOINT } from '../constants';


export class Nav extends Component {
    constructor(props) {
        super(props);

        this.state = {
            mobileMenu: false,
            yScroll: 0,
            searchBar: false,
            search: ''
        };

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);


    }

    listenToScroll = () => {
        const yScroll =
            document.body.scrollTop || document.documentElement.scrollTop


        this.setState({
            yScroll: yScroll,
            scrollHeader: yScroll < this.state.yScroll,
        })
    }

    componentDidMount() {
        fetch(API_ENDPOINT + '/menu', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',

            },
        }).then((res) => res.json()).then((result) => {
            this.setState({
                menu: result
            })

        })

        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        window.addEventListener('scroll', this.listenToScroll)

    }
    logout = () => {

        localStorage.removeItem('token');
        window.location.reload(false);

    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }


    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
        window.removeEventListener('scroll', this.listenToScroll)

        if (!this.props.socketIOClient) return;
        this.props.socketIOClient.removeAllListeners("suggestions");
        //this.props.socketIOClient.removeAllListeners("fetchProductVariations");

    }


    componentDidUpdate(prevProps) {
        if (prevProps[0].location.pathname != this.props[0].location.pathname) {
            if (typeof window !== 'undefined') { window.scrollTo(0, 0); }
            this.setState({
                mobileMenu: false
            })


        }



    }
    searchSubmit = () => {
        this.props[0].history.push(`/pretraga/${this.state.search}`);
        // this.setState({ search: ' ' })
    }
    handleKeyPress = (target) => {
        if (target.charCode == 13) {
            this.searchSubmit();
        }
    }

    render() {

        return (
            <div className={this.state.yScroll > 100 ? 'nav nav-fixed' : 'nav'}>
                <div className="nav-content">
                    <div className="logo">
                        <Link to="/">
                            <Isvg src={logo} />
                        </Link>
                    </div>
                    {/*<Isvg src={menuIcon} />*/}
                    <div className="navigation">
                        <div className="navigation-bottom">
                            <ul>
                                <li className={this.props[0].location.pathname == '/' ? 'active' : ''}>
                                    <Link to="/" className="nav-link">{'Početna'.translate(this.props.lang)}</Link>
                                </li>
                                {
                                    this.state.menu && this.state.menu.map((item, idx) => {
                                        return (
                                            <li className={this.props[0].location.pathname == '/' + Object.translate(item, 'link', this.props.lang) ? 'active' : ''}>
                                                <UncontrolledDropdown>
                                                    {
                                                        item.type == 5 ?

                                                            <DropdownToggle className="menu-link" style={{ paddingLeft: 0, marginRight: 10 }}>
                                                                <a><span className="link-text">{Object.translate(item, 'name', this.props.lang)}</span></a>
                                                                <Isvg src={arrowDown} />
                                                            </DropdownToggle>
                                                            :
                                                            <div style={{ paddingLeft: 0, paddingRight: 10 }}>
                                                                <Link to={Object.translate(item, 'footerLink', this.props.lang)}><span className="link-text">{Object.translate(item, 'name', this.props.lang)}</span></Link>
                                                                <DropdownToggle className="menu-link">

                                                                    <Isvg src={arrowDown} />
                                                                </DropdownToggle>
                                                            </div>

                                                    }

                                                    <DropdownMenu>
                                                        {
                                                            item.categories && item.categories.map((category, i) => {
                                                                return (
                                                                    <Link to={"/" + Object.translate(item, 'link', this.props.lang) + "/" + Object.translate(category, 'link', this.props.lang)}>
                                                                        <DropdownItem>
                                                                            {Object.translate(category, 'name', this.props.lang)}
                                                                        </DropdownItem>
                                                                    </Link>
                                                                )
                                                            })
                                                        }

                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </li>
                                        )

                                    })
                                }

                                <li className={this.props[0].location.pathname == '/gallery' ? 'active' : null}>
                                    <Link to="/gallery" className="nav-link">{'Galerija'.translate(this.props.lang)}</Link>
                                </li>
                                <li className={this.props[0].location.pathname == '/fortis-group' ? 'active' : null}>
                                    <Link to="/fortis-group" className="nav-link">{'FORTIS Group'.translate(this.props.lang)}</Link>
                                </li>
                                <li className={this.props[0].location.pathname == '/contact' ? 'active' : null}>
                                    <Link to="/contact" className="nav-link">{'Kontakt'.translate(this.props.lang)}</Link>
                                </li>
                            </ul>
                            <div className="languages">
                                <div className="search">
                                    <Isvg src={searchIcon} onClick={() => {
                                        this.setState({
                                            searchBar: !this.state.searchBar
                                        })
                                    }} />

                                    <div class={this.state.searchBar ? 'search-container search-container-open' : 'search-container'}>
                                        <div className="search-form">
                                            <input type="text" value={this.state.search} onKeyPress={this.handleKeyPress} onChange={(e) => {

                                                this.setState({ search: e.target.value })
                                            }} placeholder={"Pretražite sajt...".translate(this.props.lang)} />
                                            <button onClick={() => this.searchSubmit()}>
                                                <Isvg src={arrow} />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="languages-box">
                                    <UncontrolledDropdown>
                                        <DropdownToggle className="menu-link">
                                            <span className="link-text">{this.props.lang == 'ba' ? 'BA' : this.props.lang == 'en' ? 'ENG' : 'DE'}</span>
                                            <Isvg src={arrowDown} />
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem onClick={() => this.props.setLang('ba')}>BA</DropdownItem>
                                            <DropdownItem onClick={() => this.props.setLang('en')}>ENG</DropdownItem>
                                            <DropdownItem onClick={() => this.props.setLang('de')}>DE</DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="nav-social">
                        <ul className="nav-social-list">
                            <li>
                                <a href="https://ms-my.facebook.com/bijeljinaput.doo.7" target="_blank">
                                    <Isvg src={fbIcon} />
                                </a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/bnput.roadconstruction/" target="_blank">
                                    <Isvg src={instIcon} />
                                </a>
                            </li>
                            <li>
                                <a href="https://www.youtube.com/channel/UC8WPy5XYHeOCSBuKC-BvcTg" target="_blank">
                                    <Isvg src={ytIcon} />
                                </a>
                            </li>
                            <li href="" target="_blank">
                                <a>
                                    <Isvg src={liIcon} />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                {typeof window !== 'undefined' && window.innerWidth < 991 ?
                    <div className="mobile-navigation">
                        <div className="logo">
                            <Link to="/">
                                <Isvg src={logo} />
                            </Link>
                        </div>
                        <Isvg src={bars} className="menu-icon" onClick={() => {
                            this.setState({
                                mobileMenu: !this.state.mobileMenu
                            })
                        }} />
                        <div className={this.state.mobileMenu ? 'mobile-navigation-container open' : 'mobile-navigation-container'}>
                            <ul className="mobile-navigation-list">

                                <li className={this.props[0].location.pathname == '/' ? 'active' : ''}>
                                    <Link to="/" className="nav-link">{'Početna'.translate(this.props.lang)}</Link>
                                </li>
                                {
                                    this.state.menu && this.state.menu.map((item, idx) => {
                                        return (
                                            <li className={this.props[0].location.pathname == '/' + Object.translate(item, 'link', this.props.lang) ? 'active' : ''}>
                                                <UncontrolledDropdown>
                                                    <DropdownToggle className="menu-link">
                                                        {
                                                            item.type == 5 ?
                                                                <a><span className="link-text">{Object.translate(item, 'name', this.props.lang)}</span></a>
                                                                :
                                                                <Link to={Object.translate(item, 'footerLink', this.props.lang)}><span className="link-text">{Object.translate(item, 'name', this.props.lang)}</span></Link>


                                                        }
                                                        <Isvg src={arrowDownDark} />
                                                    </DropdownToggle>

                                                    <DropdownMenu>
                                                        {
                                                            item.categories && item.categories.map((category, i) => {
                                                                return (
                                                                    <Link to={"/" + Object.translate(item, 'link', this.props.lang) + "/" + Object.translate(category, 'link', this.props.lang)}>
                                                                        <DropdownItem>
                                                                            {Object.translate(category, 'name', this.props.lang)}
                                                                        </DropdownItem>
                                                                    </Link>
                                                                )
                                                            })
                                                        }

                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </li>
                                        )

                                    })
                                }

                                <li className={this.props[0].location.pathname == '/gallery' ? 'active' : null}>
                                    <Link to="/gallery" className="nav-link">{'Galerija'.translate(this.props.lang)}</Link>
                                </li>
                                <li className={this.props[0].location.pathname == '/fortis-group' ? 'active' : null}>
                                    <Link to="/fortis-group" className="nav-link">{'FORTIS Group'.translate(this.props.lang)}</Link>
                                </li>
                                <li className={this.props[0].location.pathname == '/contact' ? 'active' : null}>
                                    <Link to="/contact" className="nav-link">{'Kontakt'.translate(this.props.lang)}</Link>
                                </li>


                                <span className="close-icon" onClick={() => {
                                    this.setState({
                                        mobileMenu: false
                                    })
                                }}>x</span>
                            </ul>

                            <div className="languages">
                                <div className="languages-box">
                                    <UncontrolledDropdown>
                                        <DropdownToggle className="menu-link">
                                            <span className="link-text">{this.props.lang == 'ba' ? 'BA' : this.props.lang == 'en' ? 'ENG' : 'DE'}</span>
                                            <Isvg src={arrowDown} />
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem onClick={() => this.props.setLang('ba')}>BA</DropdownItem>
                                            <DropdownItem onClick={() => this.props.setLang('en')}>ENG</DropdownItem>
                                            <DropdownItem onClick={() => this.props.setLang('de')}>DE</DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </div>
                            </div>

                        </div>

                    </div>
                    :
                    null
                }
            </div>
        )
    }
}

export default Nav;
