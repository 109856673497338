import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import Isvg from 'react-inlinesvg';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    UncontrolledDropdown,
    Input
} from 'reactstrap';

import Page from '../containers/page';
import sf1 from '../assets/images/sf1.jpg';
import arrowRight from '../assets/svg/arrow-white.svg';
import blogImage from '../assets/images/b1.jpg';
import arrowDark from '../assets/svg/arrow-right.svg';
import BlogArticle from '../components/blogArticle';

import close_ico from '../assets/svg/close-ico.svg';

import { API_ENDPOINT } from '../constants';
import moment from 'moment';

export class ReferenceDetailsPage extends Component {
    constructor(props) {
        super(props);

        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);
        this.state = {
            gallery: [],
            activeIndex: 0,
            ...props.initialData
        };
    }

    componentDidMount() {

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get()



    }

    get = () => {
        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                }, () => {
                    if (this.state.item && this.state.item.gallery && this.state.item.gallery.length) {
                        this.setState({ gallery: this.state.item.gallery, galleryLength: this.state.item.gallery.length })
                    }
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang))
                }


                )
            })
        }


    }
    componentDidUpdate(prevProps) {
        if (prevProps[0].location.pathname != this.props[0].location.pathname) {
            if (typeof window !== 'undefined') { window.scrollTo(0, 0); }
            this.get()


            for (let i = 0; i < this.props.loadData.length; i++) {
                this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                    this.setState({
                        ...data
                    })
                })
            }
        }



    }
    onExiting() {
        this.animating = true;
    }

    onExited() {
        this.animating = false;
    }

    next() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === this.state.gallery.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    }

    previous() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? this.state.gallery.length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    }

    goToIndex(newIndex) {
        if (this.animating) return;
        this.setState({ activeIndex: newIndex });
    }

    render() {
        const { activeIndex } = this.state;
        const slides = this.state.gallery && this.state.gallery.map((item) => {
            return (
                <CarouselItem
                    tag="div"
                    key={item}
                    onExiting={this.onExiting}
                    onExited={this.onExited}
                >
                    <div className="lightbox-item">
                        <img src={API_ENDPOINT + item} />
                    </div>

                </CarouselItem>
            );
        });
        return (
            <div>

                <div className="header">
                    <div className="header-content">
                        <Container>
                            <Row>
                                <Col lg="6" sm="6">
                                    <h1 className="breadcrumb-title">{this.state.menuName}</h1>
                                </Col>
                                <Col lg="6" sm="6">
                                    <span className="breadcrumb">
                                        <Link to="/">{"Početna".translate(this.props.lang)}</Link>
                                        //
                                        <a>{this.state.menuName}</a>
                                    </span>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>

                {
                    this.state.menuType == 1 ?
                        <>
                            {/* <div className="reference-page">
                                <Container>
                                    <Row>
                                        <Col lg="2">
                                            <ul className="header-nav">
                                                <span>{this.state.menuName}</span>
                                                {
                                                    this.state.categories && this.state.categories.map((item, idx) => {
                                                        return (
                                                            <li style={{ borderTop: 0 }} className={this.props[0].match.params.category == Object.translate(item, 'link', this.props.lang) ? 'active' : ''}>
                                                                <Link to={`/${this.props[0].match.params.menu}/${Object.translate(item, 'link', this.props.lang)}`}>{Object.translate(item, 'name', this.props.lang)}</Link>
                                                            </li>
                                                        )
                                                    })
                                                }

                                            </ul>
                                        </Col>
                                        <Col lg="10">
                                            <h2 className="headline" style={{ paddingLeft: 15 }}>{this.state.categoryName}</h2>
                                            <div className="tabs tabs-reference">
                                                {
                                                    this.state.subcategories && this.state.subcategories.map((item, idx) => {
                                                        return (
                                                            <li className={this.state.item.subcategory == item._id ? "active-tab" : ''} onClick={() => { this.setState({ tab: item._id }) }}><Link to={{
                                                                pathname: `/${this.props[0].match.params.menu}/${this.state.categoryLink[this.props.lang]}`,
                                                                state: { tab: item._id }
                                                            }}>{Object.translate(item, 'name', this.props.lang)}</Link></li>
                                                        )
                                                    })
                                                }
                                            </div>

                                            <div className="tabs-content-container">
                                                <div>
                                                    {
                                                        this.state.item ?
                                                            <Row className="tabs-content work-content-box">
                                                                <Col lg="12">
                                                                    <div className="work-content-box-title">
                                                                        <h4>{Object.translate(this.state.item, 'name', this.props.lang)}</h4>
                                                                        <p>{Object.translate(this.state.item, 'shortDescription', this.props.lang)}</p>
                                                                    </div>
                                                                </Col>
                                                                <Col lg="12">
                                                                    <div className="reference-detail">
                                                                        <div dangerouslySetInnerHTML={{ __html: Object.translate(this.state.item, 'content', this.props.lang) }}></div>
                                                                    </div>
                                                                </Col>
                                                                {
                                                                    this.state.item.sections && this.state.item.sections.map((item, idx) => {
                                                                        if (item.type == 'media') {
                                                                            return (

                                                                                <Col lg={{ size: item.width.lg }} xs={{ size: item.width.xs }} className="block-section">
                                                                                    <img src={API_ENDPOINT + item.value} />
                                                                                </Col>

                                                                            )
                                                                        } else if (item.type == 'text') {
                                                                            return (

                                                                                <Col lg={{ size: item.width.lg }} xs={{ size: item.width.xs }} className="block-section" dangerouslySetInnerHTML={{ __html: Object.translate(item, 'value', this.props.lang) }}>
                                                                                </Col>

                                                                            )
                                                                        } else if (item.type == 'section') {
                                                                            return (

                                                                                <Col lg={{ size: item.width.lg }} xs={{ size: item.width.xs }} className="block-section" style={{ backgroundImage: `url(${API_ENDPOINT + item.bgImage})`, objectFit: 'cover' }} dangerouslySetInnerHTML={{ __html: item.value }}>
                                                                                </Col>


                                                                            )
                                                                        }
                                                                    })
                                                                }
                                                                {
                                                                    this.state.gallery && this.state.gallery.length ?
                                                                        <>
                                                                            <Col lg="12">
                                                                                <div className="article-gallery-title">
                                                                                    <h5>{"Foto galerija:".translate(this.props.lang)}</h5>
                                                                                </div>

                                                                            </Col>
                                                                            <div className="article-gallery">
                                                                                {
                                                                                    this.state.gallery && this.state.gallery.map((item, idx) => {
                                                                                        return (
                                                                                            <Col lg="4">
                                                                                                <div className="article-gallery-image" onClick={() => {
                                                                                                    let index = idx;
                                                                                                    this.setState({ lightbox: true, activeIndex: index })
                                                                                                }}
                                                                                                    key={idx}>
                                                                                                    <img src={API_ENDPOINT + item} />
                                                                                                </div>
                                                                                            </Col>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        null
                                                                }


                                                            </Row>
                                                            :
                                                            null
                                                    }

                                                </div>

                                            </div>
                                        </Col>
                                    </Row>
                                    {this.state.lightbox ?
                                        <div className="lightbox">
                                            <div className="close" onClick={() => this.setState({ lightbox: null })}>
                                                <Isvg src={close_ico} />
                                            </div>
                                            <div className="photo-num">
                                                <h5>{Number(this.state.activeIndex) + 1} / {this.state.galleryLength}</h5>
                                            </div>

                                            <Carousel
                                                activeIndex={activeIndex}
                                                next={this.next}
                                                previous={this.previous}
                                                autoPlay={null}
                                            >
                                                {slides}
                                                <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                                                <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
                                            </Carousel>

                                        </div>
                                        : null
                                    }
                                </Container>
                            </div>

                            <div className="blog">
                                <div className="container">
                                    <div className="blog-header">
                                        <div className="blog-title">
                                            <h2>{"Latest news".translate(this.props.lang)}</h2>
                                        </div>
                                        <Link to={this.state.linkAllNews && this.state.linkAllNews[this.props.lang]}><button>{"ALL ARTICLES".translate(this.props.lang)}<span><Isvg src={arrowDark} /></span></button></Link>
                                    </div>
                                    <div className="blog-content">
                                        <Row>
                                            {
                                                this.state.blogLatest && this.state.blogLatest.map((item, idx) => {
                                                    return (
                                                        <Col lg="4">
                                                            <BlogArticle
                                                                item={item}
                                                                image={item.image}
                                                                title={Object.translate(item, 'name', this.props.lang)}
                                                                shortDescription={Object.translate(item, 'shortDescription', this.props.lang)}
                                                                dateTS={item.dateTS}
                                                                alias={Object.translate(item, 'alias', this.props.lang)}
                                                                link={Object.translate(item, 'link', this.props.lang)}
                                                            />
                                                        </Col>
                                                    )

                                                })
                                            }
                                        </Row>
                                    </div>
                                </div>
                            </div> */}
                            <div className="reference-page">
                                <Container>
                                    <Row>
                                        <Col lg="4">
                                         
                                             <ul className="header-nav agency-nav">
                                                        <div className="agency-nav-header"><span>{this.state.menuName}</span></div>

                                                        {
                                                            this.state.categories && this.state.categories.map((item, idx) => {
                                                                return (
                                                                    <li onClick={() => this.props[0].history.push(`/${this.props[0].match.params.menu}/${Object.translate(item, 'link', this.props.lang)}`)} className={Object.translate(item, 'name', this.props.lang) == this.state.categoryName ? "active-header-nav-item agency-nav-nav-item" : "agency-nav-nav-item"} >
                                                                        <a onClick={() => this.setState({ tab: 0, detailItem: {}, gallery: [], galleryLength: 0 }, () => this.get())}>{Object.translate(item, 'name', this.props.lang)}</a>
                                                                        <ul className="header-nav">
                                                                            {
                                                                                this.state.subcategories && this.state.subcategories.map((subcategory, i) => {
                                                                                    if (subcategory.category == item._id)
                                                                                        return (
                                                                                            <Link to={{
                                                                                                pathname: `/${this.props[0].match.params.menu}/${Object.translate(item, 'link', this.props.lang)}`,
                                                                                                state: { tab: subcategory._id }
                                                                                            }}>
                                                                                                <li >
                                                                                                    <div className={this.state.item.subcategory == subcategory._id ? "active-tab" : ''}>
                                                                                                        <a>{Object.translate(subcategory, 'name', this.props.lang)}</a>
                                                                                                    </div>

                                                                                                </li>
                                                                                            </Link>
                                                                                        )
                                                                                })
                                                                            }

                                                                        </ul>
                                                                    </li>
                                                                )
                                                            })
                                                        }

                                                    </ul>
                                        </Col>
                                        <Col lg="8">
                                            <h2 className="headline" style={{ paddingLeft: 15 }}>{this.state.categoryName}</h2>
                                          
                                            

                                            <div className="tabs-content-container">
                                                <div>
                                                    {
                                                        this.state.item ?
                                                            <Row className="tabs-content work-content-box">
                                                                <Col lg="12">
                                                                    <div className="work-content-box-title">
                                                                        <h4>{Object.translate(this.state.item, 'name', this.props.lang)}</h4>
                                                                        <p>{Object.translate(this.state.item, 'shortDescription', this.props.lang)}</p>
                                                                    </div>
                                                                </Col>
                                                                <Col lg="12">
                                                                    <div className="reference-detail">
                                                                        <div dangerouslySetInnerHTML={{ __html: Object.translate(this.state.item, 'content', this.props.lang) }}></div>
                                                                    </div>
                                                                </Col>
                                                                {
                                                                    this.state.item.sections && this.state.item.sections.map((item, idx) => {
                                                                        if (item.type == 'media') {
                                                                            return (

                                                                                <Col lg={{ size: item.width.lg }} xs={{ size: item.width.xs }} className="block-section">
                                                                                    <img src={API_ENDPOINT + item.value} />
                                                                                </Col>

                                                                            )
                                                                        } else if (item.type == 'text') {
                                                                            return (

                                                                                <Col lg={{ size: item.width.lg }} xs={{ size: item.width.xs }} className="block-section" dangerouslySetInnerHTML={{ __html: Object.translate(item, 'value', this.props.lang) }}>
                                                                                </Col>

                                                                            )
                                                                        } else if (item.type == 'section') {
                                                                            return (

                                                                                <Col lg={{ size: item.width.lg }} xs={{ size: item.width.xs }} className="block-section" style={{ backgroundImage: `url(${API_ENDPOINT + item.bgImage})`, objectFit: 'cover' }} dangerouslySetInnerHTML={{ __html: item.value }}>
                                                                                </Col>


                                                                            )
                                                                        }
                                                                    })
                                                                }
                                                                {
                                                                    this.state.gallery && this.state.gallery.length ?
                                                                        <>
                                                                            <Col lg="12">
                                                                                <div className="article-gallery-title">
                                                                                    <h5>{"Foto galerija:".translate(this.props.lang)}</h5>
                                                                                </div>

                                                                            </Col>
                                                                            <div className="article-gallery">
                                                                                {
                                                                                    this.state.gallery && this.state.gallery.map((item, idx) => {
                                                                                        return (
                                                                                            <Col lg="4">
                                                                                                <div className="article-gallery-image" onClick={() => {
                                                                                                    let index = idx;
                                                                                                    this.setState({ lightbox: true, activeIndex: index })
                                                                                                }}
                                                                                                    key={idx}>
                                                                                                    <img src={API_ENDPOINT + item} />
                                                                                                </div>
                                                                                            </Col>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        null
                                                                }


                                                            </Row>
                                                            :
                                                            null
                                                    }

                                                </div>

                                            </div>
                                        </Col>
                                    </Row>
                                    {this.state.lightbox ?
                                        <div className="lightbox">
                                            <div className="close" onClick={() => this.setState({ lightbox: null })}>
                                                <Isvg src={close_ico} />
                                            </div>
                                            <div className="photo-num">
                                                <h5>{Number(this.state.activeIndex) + 1} / {this.state.galleryLength}</h5>
                                            </div>

                                            <Carousel
                                                activeIndex={activeIndex}
                                                next={this.next}
                                                previous={this.previous}
                                                autoPlay={null}
                                            >
                                                {slides}
                                                <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                                                <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
                                            </Carousel>

                                        </div>
                                        : null
                                    }
                                </Container>
                            </div>

                            <div className="blog">
                                <div className="container">
                                    <div className="blog-header">
                                        <div className="blog-title">
                                            <h2>{"Latest news".translate(this.props.lang)}</h2>
                                        </div>
                                        <Link to={this.state.linkAllNews && this.state.linkAllNews[this.props.lang]}><button>{"ALL ARTICLES".translate(this.props.lang)}<span><Isvg src={arrowDark} /></span></button></Link>
                                    </div>
                                    <div className="blog-content">
                                        <Row>
                                            {
                                                this.state.blogLatest && this.state.blogLatest.map((item, idx) => {
                                                    return (
                                                        <Col lg="4">
                                                            <BlogArticle
                                                                item={item}
                                                                image={item.image}
                                                                title={Object.translate(item, 'name', this.props.lang)}
                                                                shortDescription={Object.translate(item, 'shortDescription', this.props.lang)}
                                                                dateTS={item.dateTS}
                                                                alias={Object.translate(item, 'alias', this.props.lang)}
                                                                link={Object.translate(item, 'link', this.props.lang)}
                                                            />
                                                        </Col>
                                                    )

                                                })
                                            }
                                        </Row>
                                    </div>
                                </div>
                            </div>


                        </>
                        :
                        this.state.menuType == 4 ?
                            <div className="blog-details-container">
                                <Container>
                                    <Row>
                                        <Col lg="8">
                                            {
                                                this.state.item ?
                                                    <div className="main-article">
                                                        <h1>{Object.translate(this.state.item, 'name', this.props.lang)}</h1>
                                                        <span>{moment.unix(this.state.item.dateTS).format("DD.MM.YYYY.  |  HH:mm")}h</span>
                                                        <h6>{Object.translate(this.state.item, 'shortDescription', this.props.lang)}</h6>
                                                        <img src={API_ENDPOINT + (this.state.item.image)} />
                                                        {/* <div dangerouslySetInnerHTML={{ __html: Object.translate(this.state.item, 'content', this.props.lang) }}></div> */}
                                                        <Row>
                                                            {
                                                                this.state.item && this.state.item.sections && this.state.item.sections.map((item, idx) => {
                                                                    if (item.type == 'media') {
                                                                        return (

                                                                            <Col lg={{ size: item.width.lg }} xs={{ size: item.width.xs }} className="block-section">
                                                                                <img src={API_ENDPOINT + item.value} />
                                                                            </Col>

                                                                        )
                                                                    } else if (item.type == 'text') {
                                                                        return (

                                                                            <Col lg={{ size: item.width.lg }} xs={{ size: item.width.xs }} className="block-section" dangerouslySetInnerHTML={{ __html: Object.translate(item, 'value', this.props.lang) }}>
                                                                            </Col>

                                                                        )
                                                                    } else if (item.type == 'section') {
                                                                        return (

                                                                            <Col lg={{ size: item.width.lg }} xs={{ size: item.width.xs }} className="block-section" style={{ backgroundImage: `url(${API_ENDPOINT + item.bgImage})`, objectFit: 'cover' }} dangerouslySetInnerHTML={{ __html: item.value }}>
                                                                            </Col>


                                                                        )
                                                                    }
                                                                })
                                                            }
                                                            {
                                                                this.state.gallery && this.state.gallery.length ?
                                                                    <>
                                                                        <Col lg="12">
                                                                            <div className="article-gallery-title">
                                                                                <h5>{"Foto galerija:".translate(this.props.lang)}</h5>
                                                                            </div>

                                                                        </Col>
                                                                        <div className="article-gallery">
                                                                            {
                                                                                this.state.gallery && this.state.gallery.map((item, idx) => {
                                                                                    return (
                                                                                        <Col lg="4">
                                                                                            <div className="article-gallery-image" onClick={() => {
                                                                                                let index = idx;
                                                                                                this.setState({ lightbox: true, activeIndex: index })
                                                                                            }}
                                                                                                key={idx}>
                                                                                                <img src={API_ENDPOINT + item} />
                                                                                            </div>
                                                                                        </Col>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    null
                                                            }
                                                        </Row>
                                                    </div>
                                                    :
                                                    null
                                            }

                                        </Col>

                                        <Col lg="4">
                                            <div className="sidebar">
                                                <h2>{"Poslednje novosti".translate(this.props.lang)}</h2>
                                                {
                                                    this.state.blogLatest && this.state.blogLatest.map((item, idx) => {
                                                        return (
                                                            <BlogArticle
                                                                item={item}
                                                                image={item.image}
                                                                title={Object.translate(item, 'name', this.props.lang)}
                                                                shortDescription={Object.translate(item, 'shortDescription', this.props.lang)}
                                                                dateTS={item.dateTS}
                                                                alias={Object.translate(item, 'alias', this.props.lang)}
                                                                link={Object.translate(item, 'link', this.props.lang)}
                                                            />
                                                        )

                                                    })
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                    {this.state.lightbox ?
                                        <div className="lightbox">
                                            <div className="close" onClick={() => this.setState({ lightbox: null })}>
                                                <Isvg src={close_ico} />
                                            </div>
                                            <div className="photo-num">
                                                <h5>{Number(this.state.activeIndex) + 1} / {this.state.galleryLength}</h5>
                                            </div>

                                            <Carousel
                                                activeIndex={activeIndex}
                                                next={this.next}
                                                previous={this.previous}
                                                autoPlay={null}
                                            >
                                                {slides}
                                                <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                                                <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
                                            </Carousel>

                                        </div>
                                        : null
                                    }
                                </Container>
                            </div>
                            :
                            null
                }

            </div>


        )
    }
}

export default Page(ReferenceDetailsPage);