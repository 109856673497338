import React, { Component } from "react";
import Slider from "react-slick";
import Isvg from 'react-inlinesvg';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import feature from '../assets/svg/feature-1.svg';
import arrowRight from '../assets/svg/arrow-right.svg';
import ytIcon from '../assets/svg/yt-icon.svg';

import { API_ENDPOINT } from '../constants';
import YouTube from 'react-youtube';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    UncontrolledDropdown,
    Input,
    Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';



export default class HomeSlider extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeModalVideo: null,
        };
    }
    render() {
        var dynamicHeight =  typeof window !== 'undefined' ?  window.innerWidth/100 * 42 : 0;
        const opts = {
            
            height: typeof window !== 'undefined' && window.innerWidth > 1120 ? '715px' : dynamicHeight,
            width: typeof window !== 'undefined' && window.innerWidth > 1120 ? '1105px' : '100%',
            playerVars: {
                // https://developers.google.com/youtube/player_parameters
                autoplay: 0,
            },
        };

        const settings = {
            customPaging: (slick, index, i, targetImage, title) => {
                return (
                    <div className="slide-tab">
                        <div className="slide-tab-block">
                            <div className="slide-tab-block-container">
                                <div>
                                    <img src={this.props.slides && this.props.slides[slick].icon && API_ENDPOINT + this.props.slides[slick].icon} />
                                </div>
                                <div className="slide-tab-content">
                                    <h3>{this.props.slides && this.props.slides[slick].name && Object.translate(this.props.slides[slick], 'name', this.props.lang)}</h3>
                                </div>
                            </div>
                            <p>{this.props.slides && this.props.slides[slick].shortDescription && Object.translate(this.props.slides[slick], 'shortDescription', this.props.lang)}</p>
                        </div>
                    </div>
                );
            },
            dots: true,
            dotsClass: "slick-dots slick-thumb",
            infinite: true,
            speed: 1000,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 10*1000
        };

        return (
            <div>
                <Slider {...settings}>
                    {
                        this.props.slides && this.props.slides.map((item, idx) => {
                            return (
                                <div className="home-slide" key={idx}>
                                    <div className="container">
                                        <div className="home-slide-content">
                                            <h1 data-title="Izgradnja">{Object.translate(item, 'name', this.props.lang)}</h1>
                                            <h6>{Object.translate(item, 'detail', this.props.lang)}</h6>
                                            <div className="button-group">
                                                <a href={Object.translate(item, 'link', this.props.lang)} target="_blank">
                                                    {"DETALJNIJE".translate(this.props.lang)}
                                            <span>
                                                        <Isvg src={feature} style={{ display: 'none' }} />
                                                        <Isvg src={arrowRight} />
                                                    </span>
                                                </a>
                                                <a>
                                                    <div onClick={() => this.setState({ activeModalVideo: Object.translate(item, 'link2', this.props.lang) })}>
                                                        <Isvg src={ytIcon} />
                                                        <span>Promo video!</span>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="slider-bg-image">
                                        <img src={API_ENDPOINT + item.image} />
                                    </div>
                                </div>
                            )
                        })
                    }


                </Slider>
                {
                    this.state.activeModalVideo ?
                        <Modal isOpen={this.state.activeModalVideo} className="modal-video" centered={true} size="xl">
                            <ModalHeader toggle={() => this.setState({ activeModalVideo: !this.state.activeModalVideo })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ activeModalVideo: !this.state.activeModalVideo })}>&times;</button>}>{'Promo video'.translate(this.props.lang)}</ModalHeader>

                            <ModalBody>
                                <div className='promo-video'>
                                    <YouTube videoId={this.state.activeModalVideo} opts={opts} onReady={this._onReady} />
                                </div>
                            </ModalBody>
                        </Modal>
                        :
                        null
                }
            </div>
        );
    }
}