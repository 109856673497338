import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import sf1 from '../assets/images/sf1.jpg';

import { API_ENDPOINT } from '../constants';

export class ReferenceArticle extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {
        return (

            <div className="offer-box">
                <div className="offer-box-image">
                    <img src={API_ENDPOINT + this.props.image} />
                </div>

                {
                    this.props.refPage ?
                        <div className="offer-box-content1">
                            <h3>
                             {
                                    this.props.title && this.props.title.length > 70 ?
                                        this.props.title.substr(0, 67) + '...'
                                    :
                                        this.props.title
                                }
                            </h3>
                            <p>
                                {
                                    this.props.shortDescription && this.props.shortDescription.length > 50 ?
                                        this.props.shortDescription.substr(0, 47) + '...'
                                    :
                                        this.props.shortDescription
                                }
                            
                             </p>
                        </div>
                        :

                        <div className="offer-box-content">
                            <span>{this.props.subcategoryName}</span>
                            <h3>
                                {
                                    this.props.title && this.props.title.length > 70 ?
                                        this.props.title.substr(0, 67) + '...'
                                    :
                                        this.props.title
                                }
                            </h3>
                            <p>
                            {
                                    this.props.shortDescription && this.props.shortDescription.length > 37 ?
                                        this.props.shortDescription.substr(0, 34) + '...'
                                    :
                                        this.props.shortDescription
                                }
                            </p>
                        </div>

                }

            </div>
        )
    }
}

export default ReferenceArticle;