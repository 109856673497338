import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Header from '../components/header';
import Nav from '../components/nav';
import Footer from '../components/footer';
import Map from '../components/map';


export const DefaultLayout = (Wrapped) => (props) => {
    console.log(props)
    return (
        <div>
           
            {
                props[0].location.pathname != '/prijava' ?
                    
                    <>
                        <Header {...props} />
                        <Nav {...props} />
                        <Wrapped {...props} />
                        <Map {...props}/>
                        <Footer {...props} />
                    </>
                    :
                    <>
                        <Wrapped {...props} />
                    </>

            }
           

        </div>
    );
};

export default DefaultLayout;