import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import Isvg from 'react-inlinesvg';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    UncontrolledDropdown,
    Input
} from 'reactstrap';


import Page from '../containers/page';
import ReferenceArticle from '../components/referenceArticle';
import BlogArticle from '../components/blogArticle';
import sf1 from '../assets/images/sf1.jpg';
import arrowDark from '../assets/svg/arrow-right.svg';
import arrowLight from '../assets/svg/arrow-white.svg';
import blogImage from '../assets/images/b1.jpg';

export class EquipmentPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData
        };
    }

    componentDidMount() {

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get()



    }

    get = () => {
        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                }, () => this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang)))
            })
        }


    }
    componentDidUpdate(prevProps) {
        if (prevProps[0].location.pathname != this.props[0].location.pathname) {
            if (typeof window !== 'undefined') { window.scrollTo(0, 0); }
            this.get()


            for (let i = 0; i < this.props.loadData.length; i++) {
                this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                    this.setState({
                        ...data
                    })
                })
            }
        }



    }


    render() {
        return (
            <div>

             <div className="header">
                    <div className="header-content">
                        <Container>
                            <Row>
                                <Col lg="6" sm="6">
                                    <h1 className="breadcrumb-title">Oprema</h1>
                                </Col>
                                <Col lg="6" sm="6">
                                    <span className="breadcrumb">
                                        <a>Početna</a>
                                        //
                                        <a>O nama</a>
                                        //
                                        <a>Oprema</a>
                                    </span>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>   

                
                <div className="equipment-page">
                    <Container>
                        <Row>
                            <Col lg="2">
                                <ul className="header-nav">
                                    <span>O nama</span>
                                    <li style={{ borderTop: 0 }}>
                                        <a>Oprema</a>
                                    </li>
                                    <li>
                                        <a>Licence</a>
                                    </li>
                                    <li>
                                        <a>Sertifikati</a>
                                    </li>
                                    <li>
                                        <a>Istorijat</a>
                                    </li>
                                    <li>
                                        <a>Zaposleni</a>
                                    </li>
                                </ul>
                            </Col>
                            <Col lg="10">
                                <Row>
                                    <Col lg="12">
                                        <div className="equipment-details-content">
                                            <h2>Izgradnja, rekonstrukcija i održavanje javnih puteva</h2>
                                            <p>Preduzeće “Bijeljina put” je nastalo statusnim promjenama Državnog komunalnog preduzeća “4. Juli” Bijeljina,
                                            koje je osnovano 1949. godine. Danas je “Bijeljina put” d.o.o. Bijeljina u privatnom vlasništvu. Od momenta
                                            sprovedene privatizacije, 2001. godine “Bijeljina put” uspješno obavlja izgradnju, rekonstrukciju i asfaltiranje
                                            puteva i izvršava godišnje radove na održavanju i zaštiti magistralnih, regionalnih i lokalnih puteva od
                                            posebnog interesa za Bosnu i Hercegovinu.</p>
                                            <p>Uprava i zaposleni su svjesni da su kvalitet i zaštita okoline integrisani u ukupan sistem preduzeća, ključ za
                                            uspješan prodor i opstanak na tržištu. Preduzeće je takođe opredjeljeno da stalnim ulaganjem u znanje i
                                            savremenu tehnologiju postiže kvalitetne efekte i bolji stručni i proizvodni rad.</p>
                                        </div>
                                    </Col>
                                    <Col lg="12">
                                        <Row className="equipment-images-row">
                                            <Col lg="6">
                                                <div className="history-details-image-2">
                                                    <img src={sf1} />
                                                </div>
                                            </Col>
                                            <Col lg="6">
                                                <div className="history-details-image-2">
                                                    <img src={sf1} />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col lg="12">
                                        <div className="history-details-content">
                                            <p>Preduzeće “Bijeljina put” je nastalo statusnim promjenama Državnog komunalnog preduzeća “4. Juli” Bijeljina,
                                            koje je osnovano 1949. godine. Danas je “Bijeljina put” d.o.o. Bijeljina u privatnom vlasništvu. Od momenta
                                            sprovedene privatizacije, 2001. godine “Bijeljina put” uspješno obavlja izgradnju, rekonstrukciju i asfaltiranje
                                            puteva i izvršava godišnje radove na održavanju i zaštiti magistralnih, regionalnih i lokalnih puteva od
                                            posebnog interesa za Bosnu i Hercegovinu.</p>
                                            <p>Uprava i zaposleni su svjesni da su kvalitet i zaštita okoline integrisani u ukupan sistem preduzeća, ključ za
                                            uspješan prodor i opstanak na tržištu. Preduzeće je takođe opredjeljeno da stalnim ulaganjem u znanje i
                                            savremenu tehnologiju postiže kvalitetne efekte i bolji stručni i proizvodni rad.</p>
                                        </div>
                                    </Col>
                                    <Col lg="6">
                                        <div className="history-details-content">
                                            <p>Uprava i zaposleni su svjesni da su kvalitet i zaštita
                                            okoline integrisani u ukupan sistem preduzeća, kljuc
                                            za uspješan prodor i opstanak na tržištu. Preduzeće
                                            je takođe opredjeljeno da stalnim ulaganjem u
                                            znanje i savremenu tehnologiju postiže kvalitetne
                                            efekte i bolji stručni i proizvodni rad. <br/><br/>
                                            Uprava i zaposleni su svjesni da su kvalitet i zaštita
                                            okoline integrisani u ukupan sistem preduzeća, kljuc
                                            za uspješan prodor i opstanak na tržištu. Preduzeće
                                            je takođe opredjeljeno da stalnim ulaganjem u
                                            znanje i savremenu tehnologiju postiže.
                                            </p>
                                        </div>
                                    </Col>
                                    <Col lg="6">
                                        <div className="history-details-image-2">
                                            <img src={sf1} />
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <div className="work">
                    <div className="container">

                        <div className="work-title">
                            <h6>Radovi koje smo izvodili</h6>
                            <h2>Naše rerefence</h2>
                        </div>

                        <ul className="tabs tabs-light">
                            <li className="active-tab">Proizvodnja</li>
                            <li>Odrzavanje</li>
                            <li>Izgradnja</li>
                        </ul>
                        
                        <div className="tabs-content-container">
                            <div>
                                <Row className="tabs-content">
                                    <Col lg="4">
                                        <ReferenceArticle />
                                    </Col> 
                                    <Col lg="4">
                                        <ReferenceArticle />
                                    </Col>
                                    <Col lg="4">
                                        <ReferenceArticle />
                                    </Col>
                                </Row>
                            </div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </div> 

                <div className="help-section">
                    <a href="">
                        Sve reference
                        <span>
                            <Isvg src={arrowDark} />
                        </span>
                    </a>
                </div>

                <div className="blog">
                    <div className="container">
                        <div className="blog-header">
                            <div className="blog-title">
                                <h2>Latest news</h2>
                            </div>
                            <Link to={this.state.linkAllNews && this.state.linkAllNews[this.props.lang]}><button>ALL ARTICLES<span><Isvg src={arrowDark} /></span></button></Link>
                        </div>
                        <div className="blog-content">
                            <Row>
                            {
                                    this.state.blogLatest && this.state.blogLatest.map((item, idx) => {
                                        return (
                                            <Col lg="4">
                                                <BlogArticle
                                                    item={item}
                                                    image={item.image}
                                                    title={item.name}
                                                    shortDescription={item.shortDescription}
                                                    dateTS={item.dateTS}
                                                    alias={item.alias}
                                                    link={Object.translate(item, 'link', this.props.lang)}
                                                />
                                            </Col>
                                        )

                                    })
                                }
                            </Row>
                        </div>
                    </div>
                </div>

            </div>
            

        )
    }
}

export default Page(EquipmentPage);