import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import Isvg from 'react-inlinesvg';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    UncontrolledDropdown,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap';

import Page from '../containers/page';
import ContactForm from '../components/forms/contactForm';

import contactIcon1 from '../assets/svg/c1.svg';
import contactIcon2 from '../assets/svg/c2.svg';
import contactIcon3 from '../assets/svg/c3.svg';

import { API_ENDPOINT } from '../constants';

export class ContactPage extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }

    // componentDidUpdate(prevProps, prevState) {
    //     if (prevProps[0].location.search != this.props[0].location.search) {
    //         this.updateStateFromSearch(() => {
    //             this.get()
    //         });
    //     }
    //     if (prevProps[0].location.pathname != this.props[0].location.pathname) {
    //         this.get()
    //     }
    // }
    componentDidMount(){
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }
    }

    submit = (data) => {

        this.setState({
            formLoading: true
        }, () => {

            fetch( API_ENDPOINT + '/contact', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',

                },
                body: JSON.stringify(
                    data
                )
            }).then((res) => res.json()).then((result) => {
                this.setState({
                    //formLoading: null,
                    formDone: true
                })
            })
        })
    }


    render() {
        return (
            <div>

                <div className="header">
                    <div className="header-content">
                        <Container>
                            <Row>
                                <Col lg="6" sm="6">
                                    <h1 className="breadcrumb-title">{"Kontakt".translate(this.props.lang)}</h1>
                                </Col>
                                <Col lg="6" sm="6">
                                    <span className="breadcrumb">
                                    <Link to="/">{"Početna".translate(this.props.lang)}</Link>
                                        //
                                        <a>{"Kontakt".translate(this.props.lang)}</a>
                                    </span>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>

                <div className="contact-page">
                    <Container>
                        <Row>
                            <Col lg="12">
                                <div className="section-title">
                                    <h6>{"Kontaktirajte nas".translate(this.props.lang)}</h6>
                                    <h2>{"Kontakt".translate(this.props.lang)}</h2>
                                    <p>{"Budite slobodni da nas kontaktirate u bilo koje vrijeme.".translate(this.props.lang)}</p>
                                </div>
                            </Col>
                        </Row>
                        <Row className="contact-box-row">
                            <Col lg="4">
                                <div className="contact-box">
                                    <div className="contact-box-content">
                                        <div className="contact-icon">
                                            <Isvg src={contactIcon1} />
                                        </div>
                                        <div className="contact-text">
                                            <h6>{"Naša adresa".translate(this.props.lang)}</h6>
                                            <p>Baje Pivljanina 80 <br />
                                            76 300 Bijeljina <br />
                                            Bosna i Hercegovina
                                            </p>

                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col lg="4">
                                <div className="contact-box">
                                    <div className="contact-box-content">
                                        <div className="contact-icon">
                                            <Isvg src={contactIcon2} />
                                        </div>
                                        <div className="contact-text">
                                            <h6>{"Naši telefoni".translate(this.props.lang)}</h6>
                                            <p style={{ fontWeight: 600, marginBottom: 3 }}>Centrala:</p>
                                            <a href="tel:+38755247879" style={{ display: 'block', marginBottom: 7, color: '#312c40', fontSize: 15}}>+387 55 247 879</a>
                                            <p style={{ fontWeight: 600, marginBottom: 3 }}>Komercijalna služba:</p>
                                            <a href="tel:+38755247881" style={{ color: '#312c40', fontSize: 15 }}>+ 387 55 247 881</a>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col lg="4">
                                <div className="contact-box">
                                    <div className="contact-box-content">
                                        <div className="contact-icon">
                                            <Isvg src={contactIcon3} />
                                        </div>
                                        <div className="contact-text">
                                            <h6>{"E-mail adresa".translate(this.props.lang)}</h6>
                                            <p>info@bijeljinaput.com</p>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                                <ContactForm onSubmit={this.submit} lang={this.props.lang}/>
                            </Col>
                        </Row>
                    </Container>
                </div>

            </div>


        )
    }
}

export default Page(ContactPage);