import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import Isvg from 'react-inlinesvg';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    UncontrolledDropdown,
    Input
} from 'reactstrap';

import Text from '../components/forms/fields/text';
import Map from '../components/map';

import { API_ENDPOINT } from '../constants';

import logo from '../assets/svg/logo.svg';
import locationIcon from '../assets/svg/f-location.svg';
import phoneIcon from '../assets/svg/f-phone.svg';
import faxIcon from '../assets/svg/f-fax.svg';
import mailIcon from '../assets/svg/f-mail.svg';

import fbIcon from '../assets/svg/f-fb.svg';
import instIcon from '../assets/svg/f-inst.svg';
import twtIcon from '../assets/svg/f-twt.svg';
import ytIcon from '../assets/svg/f-yt.svg';
import liIcon from '../assets/svg/f-li.svg';
import arrow from '../assets/svg/nsl-arrow.svg';


export class Footer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            newsletterText: '',
            // errorMessage: null
        };
    }
    sendNewsletter = () => {
        // if (this.state.newsletterText.indexOf('@') == -1) {
        //     this.setState({ errorMessage: 'Pogresan unos' })
        // } else {

        // }

        this.setState({
            formLoading: true,
            // errorMessage: null
        }, () => {

            fetch(API_ENDPOINT + '/newsletter', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',

                },
                body: JSON.stringify({
                    newsletter: this.state.newsletterText
                })
            }).then((res) => res.json()).then((result) => {
                this.setState({
                    formDone: true
                })

                this.setState({ newsletterText: '' })
            })
        })

    }
    componentDidMount() {
        fetch(API_ENDPOINT + '/menu', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',

            },
        }).then((res) => res.json()).then((result) => {
            this.setState({
                menu: result
            })

        })
    }

    render() {
        return (
            <div className="footer">


                <Container>

                    <Row style={{ paddingBottom: 35 }}>
                        <Col lg="3">
                            <div className="footer-logo">
                                <Link to="/">
                                    <Isvg src={logo} />
                                </Link>
                            </div>
                        </Col>
                        <Col lg="2">
                            <div className="links-widget">
                                <h5 className="widget-heading">{"Liknovi".translate(this.props.lang)}</h5>
                                <ul className="links-widget-list">
                                    {
                                        this.state.menu && this.state.menu.map((item, idx) => {
                                            return (
                                                <li>
                                                    <Link to={Object.translate(item, 'footerLink', this.props.lang)}>{Object.translate(item, 'name', this.props.lang)}</Link>
                                                </li>

                                            )

                                        })
                                    }
                                    <li>
                                        <Link to="/contact">{"Kontakt".translate(this.props.lang)}</Link>
                                    </li>
                                  
                                </ul>
                            </div>
                        </Col>
                        <Col lg="4">
                            <div className="contact-widget">
                                <h5 className="widget-heading">{"Kontakt".translate(this.props.lang)}</h5>
                                <div className="contact-widget-block">
                                    <Isvg src={locationIcon} />
                                    <div className="contact-widget-block-content">
                                        <p>Baje Pivljanina 80, 76300 Bijeljina
                                                <br />
                                                Bosna i Hercegovina
                                            </p>
                                    </div>
                                </div>
                                <div className="contact-widget-block">
                                    <Isvg src={phoneIcon} />
                                    <div className="contact-widget-block-content">
                                        <a href="tel:+38755247879">+387 55 247 879</a>
                                    </div>
                                </div>
                                <div className="contact-widget-block">
                                    <Isvg src={faxIcon} />
                                    <div className="contact-widget-block-content">
                                        <a href="tel:+38755247884">+387 55 247 884</a>
                                    </div>
                                </div>
                                <div className="contact-widget-block">
                                    <Isvg src={mailIcon} />
                                    <div className="contact-widget-block-content">
                                        <p>info@bijeljinaput.com</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg="3">
                            <div className="newsletter-widget">
                                <h5 className="widget-heading">NEWSLETTER</h5>
                                <p>{"Sign up to oue nwsletter to stay up to date with the latest news and updates from IOGCE.".translate(this.props.lang)}</p>
                                <div className="newsletter">
                                    <div className="newsletter-container">
                                        <Text placeholder={"E-mail adress".translate(this.props.lang)} value={this.state.newsletterText} onChange={(e) => {
                                            this.setState({ newsletterText: e.target.value })
                                        }} />
                                        <button onClick={() => this.sendNewsletter()}>
                                            <Isvg src={arrow} />
                                        </button>
                                    </div>
                                </div>
                                <ul className="footer-social">
                                    <li>
                                        <a href="https://ms-my.facebook.com/bijeljinaput.doo.7" target="_blank">
                                            <Isvg src={fbIcon} />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/bnput.roadconstruction/" target="_blank">
                                            <Isvg src={instIcon} />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="" target="_blank">
                                            <Isvg src={twtIcon} />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.youtube.com/channel/UC8WPy5XYHeOCSBuKC-BvcTg" target="_blank">
                                            <Isvg src={ytIcon} />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="" target="_blank">
                                            <Isvg src={liIcon} />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                    <Row className="footer-bottom">
                        <Col lg="6" sm="6">
                            <p>Copyright © Bijeljilna Put d.o.o.  - 2021. All Rights Reserved.</p>
                        </Col>
                        <Col lg="6" sm="6" style={{ textAlign: 'right' }}>
                            <p>Created by <span> <a href="https://novamedia.agency/">nova media.</a> </span></p>
                        </Col>
                    </Row>
                </Container>

            </div>
        )
    }
}

export default Footer;